import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions, adsCreationVideoSelector } from "@modules/ads/store";
import styles from "./ads-creation-format-form.module.sass";
import { YOUTUBE_REG_EXP } from "@constants";
import { getYouTubeVideoId } from "@utils";
import { ChiperButton, TextField } from "@chiper-inc/sb-web-chiper";

interface AdsCreationFormatFormProps { }

const AdsCreationFormatForm: FC<AdsCreationFormatFormProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const video = useSelector(adsCreationVideoSelector);
  const [inputValue, setInputValue] = useState<string>(video);

  const isInValidUrl = () => !inputValue.match(YOUTUBE_REG_EXP);

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(actions.setCreationState({
      videoUrl: `https://www.youtube.com/watch?v=${getYouTubeVideoId(inputValue)}`
    }))
  }
  const hasError = !!inputValue && isInValidUrl();

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit}>
      <div className={styles.title}>{t("Carga el video del anuncio")}</div>

      <div className={styles.form}>
        <TextField
          value={inputValue}
          name="url"
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={t("Ingresa la URL de YouTube")}
          isValid={!hasError}
          helperText={hasError ? t("Esta URL no coincide con ningún vídeo en YouTube") : ""}
          full
          autoComplete="off"
          data-testid="chiper-text-field"
        />

        <ChiperButton
          text={t("Cargar")}
          type="submit"
          size="large"
          disabled={isInValidUrl()}
          data-testid="chiper-button"
        />
      </div>
    </form>
  );
}

export default AdsCreationFormatForm;
