import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import usePermissions from "@hooks/use-permissions";
import useGetRoles from "@modules/admin/hooks/use-get-roles";
import useSortTable from "@modules/admin/hooks/use-sort-table";
import { globalAdAdminStatesSelector } from "@config/store";
import { ADMIN_ROLES_TABLE } from "@modules/admin/constants";
import { adsAdminRolesSelector, adsAdminSearchValueSelector } from "@modules/admin/store";
import styles from "./admin-roles-table.module.sass";
import { Icon, Tag } from "@chiper-inc/sb-web-chiper";

interface AdminRolesTableProps {}

const AdminRolesTable: FC<AdminRolesTableProps> = () => {
  const { t } = useTranslation();
  const { sortByRolesState, activeColumn } = useSortTable();
  const { isLoading } = useGetRoles();
  const roles = useSelector(adsAdminRolesSelector);
  const adminStates = useSelector(globalAdAdminStatesSelector);
  const searchValue = useSelector(adsAdminSearchValueSelector);
  const { canUpdateRoles, canDeleteRoles } = usePermissions();
  const activeIcons = false;

  const getStateProps = (id: number) => {
    const state = adminStates.find((el) => el.id === id)!;
    return { text: t(state.key), skin: state.color };
  };

  const list = useMemo(() => (
    roles.filter((item) => item.roleName.toLowerCase().includes(searchValue.toLowerCase().trim())
  )), [roles, searchValue]);

  const handleEditionClick = () => {
    if (canUpdateRoles()) { console.log() }
  };

  const handleDeletionClick = () => {
    if (canDeleteRoles()) { console.log() }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {ADMIN_ROLES_TABLE.map((item) => (
          <div
            className={`${styles.head} ${activeColumn === 1 && styles.active}`}
            key={`table-head-${item.title}`}
          >
            {t(item.title)}
            {!!item.icon && (
              <div
                className={styles.icon}
                onClick={() => sortByRolesState()}
                data-testid="table-head-item"
              >
                <Icon size="16px" weight="bold" name={item.icon} />
              </div>
            )}
          </div>
        ))}
      </div>

      {isLoading 
        ? <div>...</div>
        : list.map((role) => (
          <div className={styles.row} key={`table-id-${role.id}`}>
            <div className={styles.cell}>{role.roleName}</div>
            <div className={styles.cell}>{role.usersCount}</div>

            <div className={styles.cell}>
              <Tag {...getStateProps(role.stateId)} />
            </div>

            <div className={`${styles.cell} ${styles.actions}`}>
              <div
                className={`
                  ${styles.icon} 
                  ${!activeIcons || !canUpdateRoles() ? styles.disabled : ""}
                `}
                onClick={handleEditionClick}
                data-testid='edit-icon'
              >
                <Icon name="PencilSimple" size={16} />
              </div>
              <div
                className={`
                  ${styles.icon} 
                  ${!activeIcons || !canDeleteRoles() ? styles.disabled : ""}
                `}
                onClick={handleDeletionClick}
                data-testid='delete-icon'
              >
                <Icon name="Trash" size={16} />
              </div>
            </div>
          </div>
      ))}
    </div>
  );
};

export default AdminRolesTable;
