import { INTIAL_USER_STATE, ONBOARDINGS } from "@constants";
import { AdAudience, AdState, Notification } from "@entities";

export interface GlobalState {
  ui: GlobalUiState;
  data: GlobalDataState;
}

export interface GlobalUiState {
  notification: Notification;
  isLoading: boolean;
}

export interface GlobalUserState {
  isLogged: boolean;
  displayName: string;
  email: string;
  photoUrl: string;
  permissionsId: number[];
  roleId: number;
  agencyId: number;
  userId: number;
}

export interface GlobalDataState {
  user: GlobalUserState;
  onboardingIdsDone: number[], 
  adStates: AdState[];
  adAdminStates: AdState[];
  adAudiences: AdAudience[];
}

export const initialState: GlobalState = {
  ui: {
    isLoading: false,
    notification: {
      show: false,
      message: 'Message',
      state: 'success'
    },
  },
  data: {
    user: INTIAL_USER_STATE,
    onboardingIdsDone: ONBOARDINGS,
    adStates: [],
    adAdminStates: [],
    adAudiences: [],
  }
}
