import React, { ChangeEvent, FC, useCallback } from 'react';
import { Icon } from '@chiper-inc/sb-web-chiper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePermissions from "@hooks/use-permissions";
import Clevertap from "@config/clevertap";
import { ADS_CREATION_ROUTE } from "@constants";
import { DASH_BUTTON_ID, DASH_SEARCHBAR_ID } from "@modules/home/constants";
import { actions } from "@modules/home/store";
import UiSearchBar from "@ui/ui-search-bar";
import styles from "./home-dashboard-top-panel.module.sass";
import UiButton from '@ui/ui-button';

interface HomeDashboardTopPanelProps { }

const HomeDashboardTopPanel: FC<HomeDashboardTopPanelProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { canCreateCampaings } = usePermissions();

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setSearchvalue(target.value))
  }, [dispatch]);

  const handleButtonClick = () => {
    navigate(ADS_CREATION_ROUTE);
    Clevertap.pushEvent("StartCreatePAds")
  };

  return (
    <div className={styles.wrapper}>
      <UiSearchBar
        onChange={handleInputChange}
        placeholder={t("Buscar por nombre o ID de campaña")}
        id={DASH_SEARCHBAR_ID}
      />

      <UiButton
        width='188px'
        height='52px'
        onClick={handleButtonClick}
        id={DASH_BUTTON_ID}
        isDisabled={!canCreateCampaings()}
      >
        <Icon name="Plus" size="24" weight="bold" />
        {t("Crear anuncio")}
      </UiButton>
    </div>
  );
}

export default HomeDashboardTopPanel;
