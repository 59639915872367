import * as yup from "yup";
import { t } from "i18next";
import { MAIL_REG_EXP, PHONE_LENGTH, REG_EXP_ONLY_NUMBERS } from "@constants";

export const userCreationSchema = yup.object().shape({
  name: yup.string()
    .required(t("Campo requerido"))
    .max(50, t("Maximo 50 digitos")),
  email: yup.string()
    .required(t('Campo requerido'))
    .matches(MAIL_REG_EXP, t('El email no es valido')),
  phone: yup.string()
    .required(t('Campo requerido'))
    .matches(REG_EXP_ONLY_NUMBERS, t("Solo se pueden numeros"))
    .max(PHONE_LENGTH, t('Maximo 10 digitos'))
    .min(PHONE_LENGTH, t('Minimo 10 digitos')),
  agencyId: yup.number()
    .positive()
    .required(t("Campo requerido")),
  roleId: yup.number()
    .positive()
    .required(t("Campo requerido")),
});
