import React, { ChangeEvent, ChangeEventHandler, FC, memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIME_TO_DEBOUNCE } from '@constants';
import { ResponseItem } from '@entities';
import chiperC from '@img/chiper-c-logo.png';
import styles from './ui-search-bar.module.sass';

interface UiSearchBarProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  defaultValue?: string;
  placeholder?: string;
  hasDebounce?: boolean;
  isDisabled?: boolean;
  hasAutoFocus?: boolean;
  results?: ResponseItem[] | null;
  onResultClick?: (i: ResponseItem) => void;
  hasClearOnResulClick?: boolean;
  id?: string;
}

const UiSearchBar: FC<UiSearchBarProps> = ({
  defaultValue = '',
  onChange,
  placeholder,
  hasDebounce,
  hasAutoFocus,
  isDisabled,
  results = null,
  onResultClick = () => true,
  hasClearOnResulClick,
  id,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const debounceFn = useRef<NodeJS.Timeout>({} as NodeJS.Timeout)

  const [hasValue, setHasValue] = useState(!!defaultValue)

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHasValue(!!ref.current?.value);
    if (debounceFn.current) clearTimeout(debounceFn.current);
    debounceFn.current = setTimeout(
      () => { onChange(e) },
      (hasDebounce && !!e.target.value) ? TIME_TO_DEBOUNCE : 0
    );
  }

  const handleCloseClick = () => {
    ref.current!.value = ''
    setHasValue(false);
    onChange(({ target: { value: '' } }) as ChangeEvent<HTMLInputElement>)
  }

  const handleResultClick = (i: ResponseItem) => {
    onResultClick(i);
    ref.current!.value = hasClearOnResulClick ? '' : i.name;
    setHasValue(!hasClearOnResulClick);
  }

  return (
    <div className={` ${styles.wrapper}  ${isDisabled && styles.disabled}`} id={id}>
      <span className={`icon-thin-magnifying-glass ${styles.icon}`} />
      <input
        ref={ref}
        type="text"
        defaultValue={defaultValue}
        className={styles.input}
        placeholder={placeholder}
        onChange={handleInputChange}
        disabled={isDisabled}
        autoFocus={hasAutoFocus}
        data-testid='ui-search-bar'
      />

      {!!hasValue &&
        <span className={`icon-thin-x ${styles.closeIcon}`} onClick={handleCloseClick} />
      }

      {!!results && (
        <div className={styles.results}>
          <div className={styles.divider} />
          {!results.length
            ? <div className={styles.empty}>
              {t("No se han encontrado CPGs relacionados")}
            </div>
            : results.map(e => (
              <div className={styles.item} key={`result-item-${e.id}`} onClick={() => handleResultClick(e)}>
                <img src={e.id === 0 ? chiperC : e.imageUrl} alt="C" className={styles.img} />
                <div className={styles.name}>{e.name}{e.id === 0 ? ' (crear)' : ''}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default memo(UiSearchBar);
