import { DashboardTableColumn } from "../entities";

export const DASHBOARD_TABLE_COLUMNS: DashboardTableColumn[] = [
  { title: "ID", icon: "ArrowsDownUp" },
  { title: "Nombre del CPG", icon: "ArrowsDownUp" },
  { title: "Nombre del anuncio", icon: "ArrowsDownUp" },
  { title: "SKU's" },
  { title: "Audiencia", icon: "ArrowsDownUp" },
  { title: "Alcance" },
  { title: "Fecha", icon: "ArrowsDownUp" },
  { title: "Estado", icon: "ArrowsDownUp" },
  { title: "Acciones" },
];
