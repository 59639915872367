import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions, adsCreationProgrammingStandarSelector, adsCreationProgrammingTypeSelector } from "@modules/ads/store";
import { PROGRAMMING_STANDARD_TYPE_ID } from '@modules/ads/constants';

interface AdsCreationProgrammingManagerProps { }

const AdsCreationProgrammingManager: FC<AdsCreationProgrammingManagerProps> = () => {
  const dispatch = useDispatch();
  const currentProgrammingType = useSelector(adsCreationProgrammingTypeSelector);
  const standard = useSelector(adsCreationProgrammingStandarSelector);

  useEffect(() => {
    const isStandard = currentProgrammingType === PROGRAMMING_STANDARD_TYPE_ID;
    dispatch(actions.setIsCurrentStepCompleted(
      isStandard && !!standard.initDate && !!standard.endDate
    ));
  }, [currentProgrammingType, dispatch, standard]);

  return <div />
}

export default AdsCreationProgrammingManager;
