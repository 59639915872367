import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS, ROUTES } from "@constants";
import '../index.sass';
import Ads from "@modules/ads";
import Home from "@modules/home";
import Auth from "@modules/auth";
import Admin from "@modules/admin";
import { globalUserSelector } from "@config/store";
import useGetGlobalInfo from "@hooks/use-get-global-info";
import UiNotification from "@ui/ui-notification";
import UiMenu from "@ui/ui-menu";
import UiGlobalLoader from "@ui/ui-global-loader";

interface MainRouterProps { }

const MainRouter: FC<MainRouterProps> = () => {
  const user = useSelector(globalUserSelector);

  return (
    <>
      <UiNotification />
      <div className='container'>
        {!user.isLogged ? (
          <Routes>
            <Route path={PATHS.login} element={<Auth />} />
            <Route path="*" element={<Navigate to={ROUTES.login} />} />
          </Routes>
        ) : (
          <>
            <GlobalInfoManager />
            <UiMenu />
            <div className="routerContainer">
              <Routes>
                <Route path={`${PATHS.home}/*`} element={<Home />} />
                <Route path={`${PATHS.ads}/*`} element={<Ads />} />
                <Route path={`${PATHS.admin}/*`} element={<Admin />} />
                <Route path="*" element={<Navigate to={ROUTES.home} />} />
              </Routes>
            </div>
          </>
        )}
      </div>
      <UiGlobalLoader />
    </>
  );
};

export default MainRouter;

const GlobalInfoManager = () => {
  useGetGlobalInfo();
  return <div />
}
