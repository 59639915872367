import axios from "axios";
import { ResponseItem, ResponseItemIdType } from "@entities";
import { sleep } from "@utils";
import { getFrequency, getWeeks } from "../../../a";
import { AdsEspecificProgrammingStateWeeks } from "../store";
import { AdsCreationSubmitState, SkuResponseItem } from "../entities/ads.entities";

export const fetchGetCPG = (value: string): Promise<ResponseItem[]> =>
  axios.get(`cpg?search=${value}`)
    .then((resp) => resp.data);

export const fetchGetSKUsByCPG = (cgpId: ResponseItemIdType, value: string): Promise<SkuResponseItem[]> =>
  axios.get(`cpg/references/${cgpId}?search=${value}`)
    .then((resp) => resp.data);

export const fetchGetCities = (): Promise<ResponseItem[]> =>
  axios.get('pos/cities')
    .then((resp) => resp.data);

export const fetchGetSerials = (): Promise<string[]> =>
  axios.get('pos/all')
    .then((resp) => resp.data);

export const fetchDoubleTemplate = (): Window | null =>
  window.open(`${process.env.REACT_APP_API_CHIPER}/campaign/template`, '_blank');

export const fetchGetFrequency = (): Promise<ResponseItem[]> =>
  sleep(1)
    .then(() => getFrequency)

export const fetchGetWeeks = (): Promise<AdsEspecificProgrammingStateWeeks[]> =>
  sleep(1)
    .then(() => getWeeks)

export const fetchGetSubmit = (body: AdsCreationSubmitState): Promise<AdsCreationSubmitState> =>
  axios.post('campaign', body)
    .then((resp) => resp.data);