import { useCallback, useRef } from "react";
import { getYouTubeVideoId } from "@utils";

const useYouTubePlayer = (
  playerId: string,
  onVideoSuccess: () => void,
  onVideoError: () => void,
) => {
  const id = useRef(playerId);
  const player = useRef<YT.Player>();

  const handleVideoChange = useCallback((videoId: string) => {
    if (!!player.current && player.current.hasOwnProperty("loadVideoById")) {
      player.current.loadVideoById(videoId);
    } else {
      player.current = new YT.Player(id.current, {
        height: "100%",
        width: "100%",
        videoId,
        events: {
          "onReady": (event) => { event.target.playVideo(); },
          "onError": onVideoError,
          "onStateChange": ({ data }) => { if (data === YT.PlayerState.PLAYING) onVideoSuccess() }
        },
        playerVars: {
          "fs": 0,
          "rel": 0,
          "showinfo": 0,
          "autoplay": 1
        }
      });
    }
  }, [onVideoError, onVideoSuccess]);


  const loadNewVideo = useCallback((videoUrl: string) => {
    handleVideoChange(getYouTubeVideoId(videoUrl));
  }, [handleVideoChange]);

  return {
    loadNewVideo,
  }
}

export default useYouTubePlayer;
