import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AdminAgencies, AdminRoles, AdminUsers, initialState } from "./admin.state";

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setRoles: ( state, { payload }: PayloadAction<AdminRoles[]>) => {
      state.data.roles = payload
    },
    setUsers: ( state, { payload }: PayloadAction<AdminUsers[]>) => {
      state.data.users = payload
    },
    setAgencies: ( state, { payload }: PayloadAction<AdminAgencies[]>) => {
      state.data.agencies = payload
    },
    setSearchValue: ( state, { payload }: PayloadAction<string>) => {
      state.data.searchValue = payload
    },
  },
});

export const actions = adminSlice.actions;
export default adminSlice.reducer;
