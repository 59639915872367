import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressHeader } from '@entities';
import styles from './ui-progress-header.module.sass';

interface UiProgressHeaderProps extends ProgressHeader { }

const UiProgressHeader: FC<UiProgressHeaderProps> = ({ currentStep, steps }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {steps.map((e, i) => (
        <div
          className={`${styles.item} ${i + 1 <= currentStep && styles.active}`}
          key={`ui-progress-header-step-${e}-${i}`}
        >
          <span className={styles.step}>{e.step}</span>
          <span className={styles.name}>{t(e.key)}</span>
          {i + 1 !== steps.length && <div className={styles.slash} />}
        </div>
      ))}
    </div>
  );
}

export default memo(UiProgressHeader);
