import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, globalUserSelector } from "@config/store";
import { INCOMPLETE_STATUS_ONBOARDING_ID } from "@constants";
import { fetchGetAdStates, fetchGetAdAdminStates, fetchGetAdAudiences, fetchGetOnboardings } from "@services";

const useGetGlobalInfo = () => {
  const dispatch = useDispatch();
  const isFinalizaed = useRef<boolean>(false);
  const user = useSelector(globalUserSelector);

  useEffect(() => {
    if (!isFinalizaed.current)
      Promise.all([fetchGetAdStates(), fetchGetAdAdminStates(), fetchGetAdAudiences()])
        .then(([states, adminStates, audiences]) => {
          dispatch(actions.setAdStates(states));
          dispatch(actions.setAdAdminStates(adminStates));
          dispatch(actions.setAdAudience(audiences));
          isFinalizaed.current = true;
        })
        .catch(() => true)
  }, [dispatch]);

  useEffect(() => {
    fetchGetOnboardings(user.userId)
      .then(resp => dispatch(actions.setOnboardingIdsDone(
        resp.reduce((acc: number[], current) => (
          current.onboardingStatusId !== INCOMPLETE_STATUS_ONBOARDING_ID
            ? [...acc, current.onboardingId] : acc
        ), [])
      )))
      .catch(() => true);
  }, [dispatch, user.userId]);

}

export default useGetGlobalInfo;
