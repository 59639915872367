import React, { FC, memo } from 'react';
import { Icon } from '@chiper-inc/sb-web-chiper';
import { IconType } from '@entities';
import styles from './ui-card.module.sass';

interface UiCardProps {
  isActive?: boolean;
  onClick?: () => void;
  title: string;
  text: string;
  icon: IconType;
  width?: string;
  isDisabled?: boolean;
}

const UiCard: FC<UiCardProps> = ({
  isActive,
  onClick = () => true,
  title,
  text,
  icon,
  width,
  isDisabled
}) => {

  const handleClick = () => {
    if (!isActive && !isDisabled) onClick();
  }

  return (
    <div
      className={`${styles.wrapper} ${isActive && styles.active} ${isDisabled && styles.disabled}`}
      onClick={handleClick}
      style={{ width }}
      data-testid='ui-card'
    >
      <div className={styles.icon}>
        <Icon name={icon} size='128' weight='thin'/>
      </div>

      <div className={styles.right}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
}

export default memo(UiCard);
