import React, { ChangeEvent, FC, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { actions } from "@modules/admin/store";
import styles from "./admin-top.module.sass";
import { Icon } from "@chiper-inc/sb-web-chiper";
import UiSearchBar from "@ui/ui-search-bar";
import UiButton from "@ui/ui-button";

interface AdminTopProps {
  placeholder: string;
  buttonText: string;
  onClick?: () => void;
  hasDisabledButton?: boolean;
}

const AdminTop: FC<AdminTopProps> = ({ placeholder, buttonText, hasDisabledButton, onClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => { dispatch(actions.setSearchValue("")) }
  }, [dispatch]);

  const handleInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.setSearchValue(target.value))
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <UiSearchBar
        placeholder={t(placeholder)}
        onChange={handleInputChange}
      />

      <UiButton width="188px" onClick={onClick} height="52px" isDisabled={hasDisabledButton}>
        <Icon name="Plus" size="24" weight="bold" />
        {t(buttonText)}
      </UiButton>
      
    </div>
  );
};

export default AdminTop;
