import React, { FC, useCallback, useEffect } from 'react';
import styles from './ui-notification.module.sass';
import { TIME_TO_HIDE_NOTIFICATION } from '@constants';
import { actions, globalUiNotificationSelector } from '@config/store';
import { useDispatch, useSelector } from 'react-redux';

interface UiNotificationProps { }

const iconStyle = {
  success: `${styles.success} icon-fill-check-circle`,
  alert: `${styles.alert} icon-fill-warning`,
  error: `${styles.error} icon-fill-x-circle`
}

const UiNotification: FC<UiNotificationProps> = () => {
  const dispatch = useDispatch();
  const { show, message, state } = useSelector(globalUiNotificationSelector);

  const hideNotification = useCallback(() => dispatch(actions.notification({ show: false })), [dispatch]);

  useEffect(() => {
    let timeoutFunction: NodeJS.Timeout;
    if (show) timeoutFunction = setTimeout(() => hideNotification(), TIME_TO_HIDE_NOTIFICATION);
    return () => { if (timeoutFunction) clearTimeout(timeoutFunction) }
  }, [hideNotification, show]);


  return (
    <div
      className={`${styles.wrapper} ${show && styles.show}`}
      onClick={hideNotification}
      data-testid='ui-notification'
    >
      <span className={`${styles.icon} ${iconStyle[state]}`} />
      <div className={styles.message}>
        {message}
      </div>
    </div>
  );
}

export default UiNotification;
