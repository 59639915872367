import { FC, ReactElement } from "react";
import { render, RenderOptions } from "@testing-library/react";
import { Provider } from "react-redux";
import { MemoryRouter } from 'react-router-dom';
import { Route, Routes } from "react-router";

import { store } from "@config/store";

export function renderWithProviders(
  ui: any,
  {
    path = "/",
    route = "/",
  } = {}
) {
  return {
    ...customRender(
      <MemoryRouter initialEntries={[route]}>
        <Routes>
          <Route path={path} element={ui} />
        </Routes>
      </MemoryRouter>
    )
  };
}

const customRender = (
  ui: ReactElement,
  options?: Omit<RenderOptions, 'wrapper'>,
) => render(ui, {wrapper: AllTheProviders, ...options})

const AllTheProviders: FC = ({ children }: any) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}
