import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { actions } from "../store";
import { fetchGetFrequency, fetchGetWeeks } from "../services";

const useGetEspecificInfo = () => {
  const dispatch = useDispatch();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchGetFrequency()
    .then((data) => dispatch(actions.setCreationState({frequency: data})))
    .catch(() => true)
    .finally(() => setIsLoading(false))
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    fetchGetWeeks()
    .then((data) => dispatch(actions.setCreationState({weeks: data})))
    .catch(() => true)
    .finally(() => setIsLoading(false))
  }, [dispatch])

  return { isLoading }
}

export default useGetEspecificInfo;