import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { actions, adsCreationCitiesWithPOSSelector } from "../store";
import { fetchGetCities } from "@modules/ads/services";

const useGetCities = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const citiesWithPOS = useSelector(adsCreationCitiesWithPOSSelector);

  useEffect(() => {
    if (!citiesWithPOS.length) {
      setIsLoading(true);
      fetchGetCities()
        .then((data) => dispatch(actions.setCreationState({ citiesWithPOS: data })))
        .catch(() => true)
        .finally(() => setIsLoading(false))
    }
  }, [citiesWithPOS, dispatch]);

  return { isLoading }
}

export default useGetCities;