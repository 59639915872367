import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./admin-agency-creation.module.sass";
import AdminAgencyForm from "@modules/admin/components/admin-agencies-form";
import AdminCreationHeader from "@modules/admin/components/admin-creation-header";

interface AdminAgencyCreationProps {}

const AdminAgencyCreation: FC<AdminAgencyCreationProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>

      <AdminCreationHeader title={t('Crear agencia')}/>

      <AdminAgencyForm />
      
    </div>
  );
};

export default AdminAgencyCreation;