import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./admin-user-creation.module.sass";
import AdminUserForm from "@modules/admin/components/admin-user-form";
import AdminCreationHeader from "@modules/admin/components/admin-creation-header";

interface AdminUserCreationProps {}

const AdminUserCreation: FC<AdminUserCreationProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>

      <AdminCreationHeader title={t('Crear nuevo perfil')}/>

      <AdminUserForm />
      
    </div>
  );
};

export default AdminUserCreation;
