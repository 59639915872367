import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ChiperButton } from "@chiper-inc/sb-web-chiper";
import { globalOnboardingsSelector } from "@config/store";
import { HOME_DASHBOARD_ONBOARDING_ID } from "@constants";
import { getOnboardingItemBoxPosition } from "@utils";
import ilustration from "@img/illustration_16.png"
import { DASH_TOOLTIP_ID, OBOARDING_STEPS as OB } from "@modules/home/constants";
import styles from "./home-dashboard-onboarding.module.sass";
import UiModal from "@ui/ui-modal";
import UiTutorialTooltip from "@ui/ui-tutorial-tooltip";
import useOnboarding from "@hooks/use-onboarding";

interface HomeDashboardOnboardingProps { }

const HomeDashboardTooltipOnboarding = () => {
  const [step, setStep] = useState(-1);
  const { t } = useTranslation();
  const { completeOnboarding, skipOnboarding } = useOnboarding();

  const onSkipOnboarding = useCallback(() => {
    skipOnboarding(HOME_DASHBOARD_ONBOARDING_ID);
  }, [skipOnboarding]);

  const onNextClick = useCallback(() => {
    if (step !== (OB.length - 1)) setStep(prev => prev + 1);
    else completeOnboarding(HOME_DASHBOARD_ONBOARDING_ID);
  }, [completeOnboarding, step]);

  if (step === -1) return (
    <UiModal closeFunction={onSkipOnboarding} className={styles.modal} blockDetectors>
      <img src={ilustration} alt="pic" className={styles.image} />

      <div className={styles.text}>
        <div className={styles.title}>
          {t("¡Bienvenido a bordo!")}
        </div>
        {t("Para comenzar, queremos acompañarte para que conozcas todas las funciones básicas de nuestra plataforma de POS Ads.")}
      </div>

      <ChiperButton
        text={t("Iniciar tour")}
        className="sbw-w-full sbw-cursor-pointer"
        onClick={() => setStep(0)}
        data-testid="chiper-button"
      />
    </UiModal>
  )

  return (
    <div className={styles.container}>
      <div style={getOnboardingItemBoxPosition(OB[step].itemBoxProps)} />

      <div className={`${styles.tooltip} ${styles[OB[step].tooltip.position]}`}>
        <UiTutorialTooltip
          id={DASH_TOOLTIP_ID}
          onBackClick={() => setStep(prev => prev - 1)}
          onNextClick={onNextClick}
          onCloseClick={onSkipOnboarding}
          title={t(OB[step].title)}
          text={t(OB[step].text)}
          totalSteps={OB.length}
          currentStep={step + 1}
          hideBackButtonOnFirstStep
          arrowPosition={OB[step].tooltip.arrowPosition as keyof object}
          arrowPositionStyles={OB[step].tooltip.arrowStyle}
        />
      </div>
    </div>
  );
}

const HomeDashboardOnboarding: FC<HomeDashboardOnboardingProps> = () => {
  const onboardingsDone = useSelector(globalOnboardingsSelector);

  if (!onboardingsDone.includes(HOME_DASHBOARD_ONBOARDING_ID))
    return <HomeDashboardTooltipOnboarding />;

  return <div />
}

export default HomeDashboardOnboarding;
