import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@config/store";
import { ResponseItem, ResponseItemIdType } from "@entities";
import {
	AdsCreationDetailState,
	AdsCreationState,
	AdsEspecficiProgrammingState,
	AdsStandrardProgrammingState,
} from "./ads.state";
import { SkuResponseItem } from "../entities/ads.entities";

export const adsCreationStepSelector = createSelector(
	(state: RootState) => state.ads.ui.currentCreationStep,
	(state: number) => state
);

export const adsUiCreationStepCompleteSelector = createSelector(
	(state: RootState) => state.ads.ui.isCurrentStepCompleted,
	(state: boolean) => state
);

export const adsUiSkuStateSelector = createSelector(
	(state: RootState) => state.ads.ui.isSkusFieldDisabled,
	(state: boolean) => state
);

export const adsCreationAudienceTypeSelector = createSelector(
	(state: RootState) => state.ads.ui.audienceCurrentType,
	(state: number) => state
);

export const adsCreationAudiencePOSSerialsSelector = createSelector(
	(state: RootState) => state.ads.ui.posSerials,
	(state: string[]) => state
);

export const adsCreationProgrammingTypeSelector = createSelector(
	(state: RootState) => state.ads.ui.programmingCurrentType,
	(state: number) => state
);

export const adsCreationSelector = createSelector(
	(state: RootState) => state.ads.data.creation,
	(state: AdsCreationState) => state
);

export const adsCreationCGPSelector = createSelector(
	(state: RootState) => state.ads.data.creation.cpg,
	(state: ResponseItem | null) => state
);

export const adsCreationDetailSelector = createSelector(
	(state: RootState) => state.ads.data.creation.detail,
	(state: AdsCreationDetailState) => state
);

export const adsCreationSKUsByCGPSelector = createSelector(
	(state: RootState) => state.ads.data.creation.skusByCPG,
	(state: SkuResponseItem[]) => state
);

export const adsCreationSerialsSelector = createSelector(
	(state: RootState) => state.ads.data.creation.serials,
	(state: string[]) => state
);

export const adsCreationCitieselector = createSelector(
	(state: RootState) => state.ads.data.creation.citiesIds,
	(state: ResponseItemIdType[]) => state
);

export const adsCreationCitiesWithPOSSelector = createSelector(
	(state: RootState) => state.ads.data.creation.citiesWithPOS,
	(state: ResponseItem[]) => state
);

export const adsCreationProgrammingStandarSelector = createSelector(
	(state: RootState) => state.ads.data.creation.standardProgramming,
	(state: AdsStandrardProgrammingState) => state
);

export const adsCreationEspecificProgrammingSelector = createSelector(
	(state: RootState) => state.ads.data.creation.especificProgramming,
	(state: AdsEspecficiProgrammingState) => state
);

export const adsCreationVideoSelector = createSelector(
	(state: RootState) => state.ads.data.creation.videoUrl,
	(state: string) => state
);

export const adsCreationAdvertisingIncomeSelector = createSelector(
	(state: RootState) => state.ads.data.creation.advertisingIncome,
	(state: string) => state
);
