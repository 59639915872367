import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { adsCreationSKUsByCGPSelector, adsUiSkuStateSelector } from '@modules/ads/store';
import { ResponseItemIdType } from '@entities';
import styles from './ads-creation-definition-skus.module.sass';
import UiSearchBar from '@ui/ui-search-bar';
import useSearchSKUs from '@modules/ads/hooks/use-search-skus';
import { Icon } from '@chiper-inc/sb-web-chiper';

interface AdsCreationDefinitionSkusProps { }

const AdsCreationDefinitionSkus: FC<AdsCreationDefinitionSkusProps> = () => {
  const { t } = useTranslation();
  const isDisabled = useSelector(adsUiSkuStateSelector);
  const skus = useSelector(adsCreationSKUsByCGPSelector);
  const data = useSearchSKUs();

  const onRemoveClick = (id: ResponseItemIdType) => {
    if (!isDisabled) data.removeSKU(id)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t('SKU o servicios')}
      </div>

      <UiSearchBar
        onChange={data.searchSKUs}
        hasAutoFocus
        isDisabled={isDisabled}
        placeholder={t('Escriba el SKU o servicio asociado')}
        results={data.results?.map(e => ({ ...e, id: e.sku }))}
        onResultClick={data.selectSKU}
        hasClearOnResulClick
        hasDebounce
      />

      <div className={`${styles.skus} ${isDisabled && styles.disabled}`}>
        {skus.map(e => (
          <div
            className={styles.card}
            key={`sku-item-${e.sku}`}
            onClick={() => onRemoveClick(e.sku)}
          >
            <img src={e.imageUrl} alt="C" className={styles.img} />

            <span className={styles.text}>{e.name}</span>

            <div className={styles.icon}>
              <Icon name='X' size="16px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdsCreationDefinitionSkus;
