import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationAdvertisingIncomeSelector, adsCreationCGPSelector, adsCreationDetailSelector, adsCreationSKUsByCGPSelector } from '@modules/ads/store';

interface AdsCreationDefinitionManagerProps { }

const AdsCreationDefinitionManager: FC<AdsCreationDefinitionManagerProps> = () => {
  const dispatch = useDispatch();
  const cpg = useSelector(adsCreationCGPSelector);
  const detail = useSelector(adsCreationDetailSelector);
  const skus = useSelector(adsCreationSKUsByCGPSelector);
  const advertisingIncome = useSelector(adsCreationAdvertisingIncomeSelector);
  const isSkusFieldsDisabled = useRef<boolean>(true);
  const isCompleteStep = useRef<boolean>(false);

  const regex = /^[1-9]\d*$/; // Only positive integers
  const isValid = regex.test(advertisingIncome);

  useEffect(() => {
    isSkusFieldsDisabled.current = !cpg || !detail.name || !detail.description || !detail.keywords || !advertisingIncome;
    dispatch(actions.setIsSkusFieldDisabled(isSkusFieldsDisabled.current));
  }, [cpg, detail.description, detail.keywords, detail.name, dispatch, advertisingIncome]);

  useEffect(() => {
    if (!cpg && !!skus.length) dispatch(actions.setCreationState({ skusByCPG: [] }))
  }, [cpg, dispatch, skus.length]);

  useEffect(() => {
    isCompleteStep.current = !isSkusFieldsDisabled.current && isValid;
    dispatch(actions.setIsCurrentStepCompleted(isCompleteStep.current));
  }, [dispatch, cpg, detail, advertisingIncome]);

  return <div />
}

export default AdsCreationDefinitionManager;
