import { ClevertapEventName, ClevertapEventPayload } from "./clevertap.entities";

interface ClervertapEvent {
  push: (name: string) => void
}
interface ClevertapGlobal {
  event: ClervertapEvent
}
declare global {
  interface Window { clevertap: ClevertapGlobal }
}

const clevertap = window.clevertap as any

const pushEvent = (eventName: ClevertapEventName, payload?: ClevertapEventPayload): void => {
  clevertap.event.push(eventName, {
    ...payload,
  });
};

const identifyUser = (name: string, id: number, email: string) => {
  clevertap.onUserLogin.push({
    Site: {
      Name: name,
      Identity: id,
      Email: email,
    }
  })
}

const Clevertap = {
  pushEvent,
  identifyUser
};

export default Clevertap;
