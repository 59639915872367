import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import useAgencyCreation from '@modules/admin/hooks/use-agency-creation';
import { AGENCY_FORM } from '@modules/admin/constants';
import { agencyCreationSchema } from '@modules/admin/schemas/agency-creation-schemas';
import styles from './admin-agencies-form.module.sass';
import { TextField } from '@chiper-inc/sb-web-chiper';
import UiButton from '@ui/ui-button';

interface AdminAgenciesFormProps { }

const AdminAgenciesForm: FC<AdminAgenciesFormProps> = () => {
  const { t } = useTranslation();
  const { createNewAgency } = useAgencyCreation();

  const handleSubmit = () => {
    createNewAgency(form.values);
  };

  const form = useFormik({
    initialValues: AGENCY_FORM,
    validationSchema: agencyCreationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <div className={styles.left}>

        <div className={styles.title}>{t("Datos de la agencia")}</div>
        <div className={styles.subtitle}>{t("Escribe el nombre de la agencia")}</div>
        <TextField
          value={form.values.name}
          name="name"
          placeholder={t("Nombre de la agencia")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.name || !form.touched.name}
          helperText={!form.touched.name ? undefined : form.errors.name}
          type="text"
          full
          autoComplete="off"
        />


        <div className={styles.title}>{t("Datos de contacto")}</div>
        <div className={styles.subtitle}>{t("Escribe el nombre del admin")}</div>
        <TextField
          value={form.values.adminName}
          name="adminName"
          placeholder={t("Datos del usuario")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.adminName || !form.touched.adminName}
          helperText={!form.touched.adminName ? undefined : form.errors.adminName}
          type="text"
          full
          autoComplete="off"
        />

      </div>

      <div className={styles.right}>
        <div className={styles.subtitle}>{t("Escribe el NIT de la agencia")}</div>
        <TextField
          value={form.values.taxNumber}
          name="taxNumber"
          placeholder={t("NIT de la agencia")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.taxNumber || !form.touched.taxNumber}
          helperText={!form.touched.taxNumber ? undefined : form.errors.taxNumber}
          type="text"
          full
          autoComplete="off"
        />
        <div className={styles.subtitle}>{t("Escribe el correo electrónico asociado al admin")}</div>
        <TextField
          value={form.values.adminEmail}
          name="adminEmail"
          placeholder={t("Correo electrónico")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.adminEmail || !form.touched.adminEmail}
          helperText={!form.touched.adminEmail ? undefined : form.errors.adminEmail}
          type="text"
          full
          autoComplete="off"
        />
      </div>

      <div className={styles.submit}>
        <UiButton
          type="submit"
          size="large"
          width="188px"
          isDisabled={!form.isValid || !form.dirty}
        >
          {t("Crear")}
        </UiButton>
      </div>

    </form>
  );
}

export default AdminAgenciesForm;
