import React, { ChangeEvent, ChangeEventHandler, FC, memo } from 'react';
import styles from './ui-text-area-field.module.sass';

interface UiTextAreaFieldProps {
  label?: string;
  name?: string;
  value?: string;
  isDisabled?: boolean;
  hasAutoFocus?: boolean;
  hasError?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  helperText?: string;
  placeholder?: string;
  onFocus?: ChangeEventHandler<HTMLTextAreaElement>;
  maxCharacters?: number;
  height?: string;
}

const UiTextAreaField: FC<UiTextAreaFieldProps> = ({
  hasError = false,
  label = '',
  name,
  value,
  isDisabled,
  hasAutoFocus,
  onChange = () => true,
  onBlur,
  helperText = '',
  placeholder = '',
  onFocus,
  error = '',
  maxCharacters,
  height,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!maxCharacters || e.target.value.length <= maxCharacters) onChange(e)
  }

  return (
    <div className={styles.container}>
      <div
        className={`
          ${styles.wrapper} 
          ${hasError && styles.error} 
          ${isDisabled && styles.disabled}
        `}
        style={{ height }}
      >
        <span className={`${styles.label} ${!!value && styles.visible}`}>{label}</span>
        <textarea
          data-testid="ui-text-area-field"
          name={name}
          value={value}
          className={styles.input}
          onChange={handleInputChange}
          onBlur={onBlur}
          autoFocus={hasAutoFocus}
          disabled={isDisabled}
          placeholder={placeholder}
          autoComplete='off'
          onFocus={onFocus}
        />
      </div>

      {(hasError && !!error)
        ? <p className={styles.errorMessage}>{error}</p>
        : <p className={styles.helperText}>{helperText}</p>
      }

      {!!maxCharacters && (
        <span className={styles.count}>
          {value?.length || '0'}/{maxCharacters}
        </span>
      )}
    </div>
  );
}

export default memo(UiTextAreaField);
