import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { globalUiLoaderSelector } from '@config/store';
import styles from './ui-global-loader.module.sass';

interface UiGlobalLoaderProps { }

const UiGlobalLoader: FC<UiGlobalLoaderProps> = () => {
  const isLoading = useSelector(globalUiLoaderSelector);

  return (
    <>
      {isLoading && <div className={styles.loader} data-testid='global-loader' />}
    </>
  );
}

export default UiGlobalLoader;
