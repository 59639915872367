import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useGetRoles from './hooks/use-get-roles';
import useGetAgencies from './hooks/use-get-agencies';
import styles from './admin.module.sass';
import AdminRouter from './routers/admin.router';


interface AdminProps { }

const Admin: FC<AdminProps> = () => {
  const { t } = useTranslation();
  const { isLoading: roles } = useGetRoles();
  const { isLoading: agencies } = useGetAgencies();

  if ( roles && agencies ) return <div data-testid='loading'>...</div>

  return (
    <div className={styles.container}>

      <div className={styles.title}>
        {t('Perfiles y roles')}
      </div>

      <AdminRouter />
    </div>
  );
}

export default Admin;
