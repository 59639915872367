import { CSSProperties } from "react";

export const getOnboardingItemBoxPosition = ({ itemId, paddingX = 24, paddingY = 18 }: {
  itemId: string;
  paddingX?: number;
  paddingY?: number;
}): CSSProperties | undefined => {
  const element = document.getElementById(itemId);
  if (!!element) return {
    width: `${element.offsetWidth + (paddingX * 2)}px`,
    height: `${element.offsetHeight + (paddingY * 2)}px`,
    borderWidth: `${element.offsetTop - paddingY}px 100vw 100vh ${element.offsetLeft - paddingX}px`,
    boxSizing: "content-box",
    borderStyle: "solid",
    transition: "0.5s",
    borderColor: "var(--transparent-1)",
    position: "relative"
  }
}
