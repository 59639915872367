import axios, { AxiosError, AxiosResponse } from "axios";
import { INVALID_TOKEN_STATUS, STORAGE_TOKEN } from "@constants";
import { Credentials } from "@entities";

axios.defaults.baseURL = process.env.REACT_APP_API_CHIPER;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(request => {
  const tokenString = sessionStorage.getItem(STORAGE_TOKEN);
  const tokens = JSON.parse(tokenString || "{}");

  if (tokens.accessToken && request.headers && !request.headers.Authorization) {
    request.headers["Authorization"] = `Bearer ${tokens.accessToken}`;
  }
  return request;
}, error => { return Promise.reject(error); });

interface QueueItem {
  resolve: (value?: string) => void;
  reject: (reason: string) => void;
}
const failedQueue: QueueItem[] = [];
let isRefreshing: boolean = false;


axios.interceptors.response.use((request: AxiosResponse) => request, async (error: AxiosError) => {
  if (error.response?.status === INVALID_TOKEN_STATUS) {
    try {
      error.response.config.headers.Authorization = undefined;

      if (isRefreshing) return new Promise((resolve, reject) => failedQueue.push({ resolve, reject }))
        .then(() => axios(error.response!.config))
        .catch(err => Promise.reject(err));

      isRefreshing = true;

      const storage = JSON.parse(sessionStorage.getItem(STORAGE_TOKEN) || "");
      const { data } = await axios.post<Credentials>(`/auth/refresh-token`, {
        refreshToken: storage.refreshToken,
      });
      sessionStorage.setItem(STORAGE_TOKEN, JSON.stringify({ ...storage, ...data }));

      failedQueue.forEach(request => request.resolve());
      isRefreshing = false;

      return axios(error.response.config);
    }

    catch { sessionStorage.removeItem(STORAGE_TOKEN); }
  }
  return Promise.reject(error);
});
