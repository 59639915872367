import { actions, store } from "@config/store";
import { NotificationTypeState } from "@entities";

export const sortAscNumberFn = (a: number, b: number) => a - b;
export const sortDscNumberFn = (a: number, b: number) => b - a;

export const sortAscStringFn = (a: string, b: string) => a.localeCompare(b);
export const sortDscStringFn = (a: string, b: string) => b.localeCompare(a);

export const showNotification = (message: string, state: NotificationTypeState) => {
  store.dispatch(actions.notification({
    message,
    state,
    show: true,
  }))
}

export const getYouTubeVideoId = (videoUrl: string) => {
  const getVideoId = (a: string, b: string) => videoUrl.split(a)[1].split(b)[0];

  if (videoUrl.includes("?v=")) return getVideoId("?v=", "&");

  if (videoUrl.includes("youtube.com/v/")) return getVideoId("youtube.com/v/", "?");

  if (videoUrl.includes("youtu.be/")) return getVideoId("youtu.be/", "?");

  if (videoUrl.includes("youtube.com/embed/")) return getVideoId("youtube.com/embed/", "?");

  if (videoUrl.includes("youtube.com/live/")) return getVideoId("youtube.com/live/", "?");

  return ""
}
