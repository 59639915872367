import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './global.slice';
import homeReducer from '@modules/home/store/home.slice';
import adsReducer from '@modules/ads/store/ads.slice';
import adminReducer from '@modules/admin/store/admin.slice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    home: homeReducer,
    ads: adsReducer,
    admin: adminReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
