import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/ads/store';
import { ADS_CREATION_VERIFICATION_STEP } from '@modules/ads/constants';
import styles from './ads-creation-verification.module.sass';
import AdsCreationVerificationFieldsInfo from '@modules/ads/components/ads-creation-verification-fields-info';
import AdsCreationVerificationDuration from '@modules/ads/components/ads-creation-verification-duration';
import AdsCreationVideoPlayer from '@modules/ads/components/ads-creation-video-player/ads-creation-video-player';

interface AdsCreationVerificationProps { }

const AdsCreationVerification: FC<AdsCreationVerificationProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setCurrentCreationStep(ADS_CREATION_VERIFICATION_STEP))
  }, [dispatch]);

  return (
    <div className={styles.container}>

      <div className={styles.infos}>
        <AdsCreationVerificationFieldsInfo />
        <AdsCreationVerificationDuration />
      </div>

      <AdsCreationVideoPlayer />

    </div>
  );
}

export default AdsCreationVerification;
