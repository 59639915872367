import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalUserState, initialState } from './global.state';
import { AdAudience, AdState, Notification } from "@entities";
import { INTIAL_USER_STATE } from "@constants";

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    notification: (state, action: PayloadAction<Partial<Notification>>) => {
      state.ui.notification = { ...state.ui.notification, ...action.payload };
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.ui.isLoading = action.payload;
    },
    setUser: (state, action: PayloadAction<GlobalUserState>) => {
      state.data.user = action.payload;
    },
    singOut: (state) => {
      state.data.user = INTIAL_USER_STATE;
    },
    setNewOnboardingIdDone: (state, action: PayloadAction<number>) => {
      state.data.onboardingIdsDone = [...state.data.onboardingIdsDone, action.payload];
    },
    setOnboardingIdsDone: (state, action: PayloadAction<number[]>) => {
      state.data.onboardingIdsDone = action.payload;
    },
    setAdStates: (state, action: PayloadAction<AdState[]>) => {
      state.data.adStates = action.payload;
    },
    setAdAudience: (state, action: PayloadAction<AdAudience[]>) => {
      state.data.adAudiences = action.payload;
    },
    setAdAdminStates: (state, action: PayloadAction<AdState[]>) => {
      state.data.adAdminStates = action.payload;
    },
  }
});

export const actions = globalSlice.actions;
export default globalSlice.reducer;
