import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DASH_TITLE_ID } from "@modules/home/constants";
import clevertap from "@config/clevertap";
import HomeDashboardTable from "@modules/home/components/home-dashboard-table";
import HomeDashboardTopPanel from "@modules/home/components/home-dashboard-top-panel";
import HomeDashboardOnboarding from "@modules/home/components/home-dashboard-onboarding";
import styles from "./home-dashboard.module.sass";

interface HomeDashboardProps { }

const HomeDashboard: FC<HomeDashboardProps> = () => {
  const { t } = useTranslation();

  useEffect(() => clevertap.pushEvent("StartDashboardPAds"), []);

  return (
    <div className={styles.container}>
      <h1 className={styles.head} id={DASH_TITLE_ID}>
        {t("Tablero de anuncios")}
      </h1>

      <HomeDashboardTopPanel />

      <HomeDashboardTable />

      <HomeDashboardOnboarding />
    </div>
  );
}

export default HomeDashboard;
