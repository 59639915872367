import React, { FC, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Tag } from '@chiper-inc/sb-web-chiper';
import { differenceInCalendarDays, format, isBefore } from 'date-fns';
import emptyStateImg from "@img/illustration_56.png";
import { globalAdAudiencesSelector, globalAdStatesSelector } from '@config/store';
import { homeDashboardAdsSelector, homeDashboardSearchValue } from '@modules/home/store';
import Clevertap from '@config/clevertap';
import { DASHBOARD_TABLE_COLUMNS, DASH_TABLE_ID } from '@modules/home/constants';
import { ResponseItem } from '@entities';
import usePermissions from "@hooks/use-permissions";
import useGetAds from '@modules/home/hooks/use-get-ads';
import useSortTable from '@modules/home/hooks/use-sort-table';
import styles from './home-dashboard-table.module.sass';
import HomeDashboardTableDetailList from '../home-dashboard-table-detail-list';
import useOutsideClickDetector from '@hooks/use-outside-click-detector';
import UiEmptyState from '@ui/ui-empty-state/ui-empty-state';

interface HomeDashboardTableProps { }

const HomeDashboardTable: FC<HomeDashboardTableProps> = () => {
  const { t } = useTranslation();
  const ads = useSelector(homeDashboardAdsSelector);
  const searchValue = useSelector(homeDashboardSearchValue);
  const adStates = useSelector(globalAdStatesSelector);
  const adAudiences = useSelector(globalAdAudiencesSelector);
  const { isLoading } = useGetAds();
  const { sortBy, activeColumn } = useSortTable();
  const { canUpdateCampaings, canDeleteCampaings } = usePermissions();
  const activeIcons = false;

  const getDiffDate = (init: string | Date, end: string | Date) => {
    if (isBefore(new Date(), new Date(init)))
      return `Inicia en ${differenceInCalendarDays(new Date(init), new Date())} dias`;

    const diff = differenceInCalendarDays(new Date(end), new Date());
    return diff > 0 ? `Finaliza en ${diff} dias` : "Finalizado"
  }

  const getAdStateProps = (id: number) => {
    const state = adStates.find(el => el.id === id)!
    return { text: t(state.key), skin: state.color }
  };

  const list = useMemo(() => (
    isNaN(+searchValue)
      ? ads.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase().trim()))
      : ads.filter(item => String(item.id).includes(searchValue.trim()))
  ), [ads, searchValue]);

  const handleEditionClick = () => {
    if (canUpdateCampaings()) { Clevertap.pushEvent("StartEditPAds") }
  }

  const handleDeletionClick = () => {
    if (canDeleteCampaings()) { console.log() }
  }

  const CaretDownIcon = ({ items }: { items: Partial<ResponseItem>[] }) => {
    const [isActive, setIsActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    useOutsideClickDetector(ref, () => setIsActive(false));

    return (
      <div className={styles.icon} onClick={() => setIsActive(prev => !prev)} ref={ref} data-testid="caret-down">
        <Icon name="CaretDown" size="16px" />
        {isActive && <HomeDashboardTableDetailList items={items} />}
      </div>
    )
  }

  return (
    <div className={styles.table}>
      <div className={styles.row} id={DASH_TABLE_ID}>
        {DASHBOARD_TABLE_COLUMNS.map((col, i) => (
          <div className={`${styles.head} ${activeColumn === i && styles.active}`} key={`table-head-${col.title}`}>
            {t(col.title)}
            {!!col.icon && (
              <div className={styles.icon} onClick={() => sortBy(i)} data-testid="table-head-item">
                <Icon size="16px" weight="bold" name={col.icon} />
              </div>
            )}
          </div>
        ))}
      </div>

      {(!isLoading && !list.length) && (
        <UiEmptyState
          img={emptyStateImg}
          title={t("¡Crea un primer anuncio!")}
          text={t("Una vez creado lo verás en tu tablero de anuncios.")}
          className={styles.emptyState}
        />
      )}

      {isLoading ? (
        <div>...</div>
      ) : (
        list.map(e => (
          <div className={styles.row} key={`table-head-${e.id}`}>
            <div className={styles.cell}>{e.id}</div>
            <div className={styles.cell}>{e.cpg}</div>
            <div className={styles.cell}>{e.name}</div>
            <div className={styles.cell}>
              {e.skus.length}
              <CaretDownIcon items={e.skus} />
            </div>
            <div className={styles.cell}>
              {t(adAudiences.find(a => a.id === e.audience.id)?.key || "")}
              <CaretDownIcon items={e.audience.values.map((item) => ({ name: item }))} />
            </div>
            <div className={styles.cell}>{e.scope}</div>
            <div className={styles.cell}>
              <div className={styles.dates}>
                {`${format(new Date(e.initDate), "dd/MM/yy")} - ${format(new Date(e.endDate), "dd/MM/yy")}`}
                <span className={styles.countDate}>{getDiffDate(e.initDate, e.endDate)}</span>
              </div>
            </div>
            <div className={styles.cell}>
              <Tag {...getAdStateProps(e.stateId)} />
            </div>
            <div className={`${styles.cell} ${styles.actions}`}>
              <div
                className={`${styles.icon} ${!activeIcons || !canUpdateCampaings() ? styles.disabled : ""}`}
                onClick={handleEditionClick}
                data-testid='edit-icon'
              >
                <Icon name="DownloadSimple" size={16} />
              </div>
              <div
                className={`${styles.icon} ${!activeIcons || !canDeleteCampaings() ? styles.disabled : ""}`}
                onClick={handleDeletionClick}
                data-testid='delete-icon'
              >
                <Icon name="PencilSimple" size={16} />
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default HomeDashboardTable;
