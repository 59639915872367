import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../store';
import { fetchGetUsers } from '../services';

const useGetUsers = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchGetUsers()
      .then((data) => dispatch(actions.setUsers(data)))
      .catch(() => true)
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  return {
    isLoading
  }
};

export default useGetUsers;