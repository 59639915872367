import { RootState } from "@config/store";
import { createSelector } from "@reduxjs/toolkit";
import { AdminAgencies, AdminRoles, AdminUsers } from "./admin.state";

export const adsAdminRolesSelector = createSelector(
  (state: RootState) => state.admin.data.roles,
  (state: AdminRoles[]) => state
);

export const adsAdminUsersSelector = createSelector(
  (state: RootState) => state.admin.data.users,
  (state: AdminUsers[]) => state
);

export const adsAdminAgenciesSelector = createSelector(
  (state: RootState) => state.admin.data.agencies,
  (state: AdminAgencies[]) => state
);

export const adsAdminSearchValueSelector = createSelector(
  (state: RootState) => state.admin.data.searchValue,
  (state: string) => state
);
