import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '@constants';
import { ADS_CREATION_TABS, ADS_TABS } from '../constants';
import AdsCreationContainer from '../components/ads-creation-container';
import AdsCreationVerification from '../pages/ads-creation-verification';
import AdsCreationProgramming from '../pages/ads-creation-programming';
import AdsCreationDefinition from '../pages/ads-creation-definition';
import AdsCreationAudience from '../pages/ads-creation-audience';
import AdsCreationFormat from '../pages/ads-creation-format';

interface AdsRouterProps { }

const AdsRouter: FC<AdsRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<div data-testid='ads' />} />

      <Route path={ADS_TABS.creation} element={<AdsCreationContainer />}>
        <Route index element={<Navigate to={ADS_CREATION_TABS.definition} />} />
        <Route path={ADS_CREATION_TABS.format} element={<AdsCreationFormat />} />
        <Route path={ADS_CREATION_TABS.audience} element={<AdsCreationAudience />} />
        <Route path={ADS_CREATION_TABS.definition} element={<AdsCreationDefinition />} />
        <Route path={ADS_CREATION_TABS.programming} element={<AdsCreationProgramming />} />
        <Route path={ADS_CREATION_TABS.verification} element={<AdsCreationVerification />} />
      </Route>

      <Route path={ADS_TABS.update} element={<div />} />

      <Route path="*" element={<Navigate to={ROUTES.ads} />} />
    </Routes>
  );
}

export default AdsRouter;
