import { ROUTES } from "@constants";
import { ProgressHeaderStep } from "@entities";
import { AdsCreationState } from "../store";
import { ADS_CREATION_TABS, ADS_PATH, ADS_TABS } from "./ads.constants";

export const ADS_CREATION_DEFINITION_STEP = 1;
export const ADS_CREATION_Audience_STEP = 2;
export const ADS_CREATION_PROGRAMMING_STEP = 3;
export const ADS_CREATION_FORMAT_STEP = 4;
export const ADS_CREATION_VERIFICATION_STEP = 5;

export const ADS_CREATION_PROGRESS_STEPS: ProgressHeaderStep[] = [
  { step: ADS_CREATION_DEFINITION_STEP, key: 'Definición' },
  { step: ADS_CREATION_Audience_STEP, key: 'Audiencia' },
  { step: ADS_CREATION_PROGRAMMING_STEP, key: 'Programación' },
  { step: ADS_CREATION_FORMAT_STEP, key: 'Formato' },
  { step: ADS_CREATION_VERIFICATION_STEP, key: 'Verificación' },
];

export const CREATION_NEXT_BUTTON_ROUTES = new Map<number, string>([
  [ADS_CREATION_DEFINITION_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.audience}`],
  [ADS_CREATION_Audience_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.programming}`],
  [ADS_CREATION_PROGRAMMING_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.format}`],
  [ADS_CREATION_FORMAT_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.verification}`],
  [ADS_CREATION_VERIFICATION_STEP, ''],
]);

export const CREATION_RETURN_BUTTON_ROUTES = new Map<number, string>([
  [ADS_CREATION_DEFINITION_STEP, ROUTES.home],
  [ADS_CREATION_Audience_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.definition}`],
  [ADS_CREATION_PROGRAMMING_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.audience}`],
  [ADS_CREATION_FORMAT_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.programming}`],
  [ADS_CREATION_VERIFICATION_STEP, `${ADS_PATH}/${ADS_TABS.creation}/${ADS_CREATION_TABS.format}`],
]);

export const CREATION_INITIAL_STATE: AdsCreationState = {
  cpg: null,
  detail: {
    name: '',
    description: '',
    keywords: []
  },
  owner: '',
  skusByCPG: [],
  citiesIds: [],
  citiesWithPOS: [],
  serials: [],
  identifySerials: [],
  frequency: [{
    id: 0,
    name: '',
  }],
  weeks: [{
    value: '',
    text: '',
  }],
  standardProgramming: {
    initDate: '',
    endDate: ''
  },
  especificProgramming: {
    initDate: '',
    endDate: '',
    initTime: '',
    endTime: '',
    weekRepeat: '1',
    dayRepeat: [
      { id: 1, name: "L" },
      { id: 2, name: "M" },
      { id: 3, name: "M" },
      { id: 4, name: "J" },
      { id: 5, name: "V" },
      { id: 6, name: "S" },
      { id: 7, name: "D" },
    ]},
  videoUrl: '',
  advertisingIncome: '',
}


export const AUDIENCE_BY_CITIES_TYPE_ID = 1;
export const AUDIENCE_BY_FILE_SERIALS_TYPE_ID = 2;
export const AUDIENCE_BY_MANUAL_SERIALS_TYPE_ID = 3;

export const PROGRAMMING_STANDARD_TYPE_ID = 1;
export const PROGRAMMING_SPECIFIC_TYPE_ID = 2;

export const AUDIENCE = new Map<number, string>([
  [AUDIENCE_BY_CITIES_TYPE_ID, 'Por ciudad'],
  [AUDIENCE_BY_FILE_SERIALS_TYPE_ID, 'Por serial (Excel)'],
  [AUDIENCE_BY_MANUAL_SERIALS_TYPE_ID, 'Por serial (manual)'],
]);
