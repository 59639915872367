import { Path, Route } from "@entities";

export const PATHS: Path = {
  home: 'home',
  login: 'login',
  ads: 'ads',
  admin: 'admin',
}

export const ROUTES: Route = {
  empty: '',
  home: `/${PATHS.home}`,
  login: `/${PATHS.login}`,
  ads: `/${PATHS.ads}`,
  admin: `/${PATHS.admin}`,
}

export const ADS_CREATION_PATH = 'creation';
export const ADS_CREATION_ROUTE = `${ROUTES.ads}/${ADS_CREATION_PATH}`;
