import { fetchGetSubmit } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { adsCreationAudienceTypeSelector, adsCreationCitiesWithPOSSelector, adsCreationSelector } from "../store";
import { useCallback } from "react";
import Clevertap from "@config/clevertap";
import { AUDIENCE_BY_CITIES_TYPE_ID } from "../constants";
import { showNotification } from "@utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@constants";
import { AdsCreationSubmitState } from "../entities/ads.entities";

const useSubmitAd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const creation = useSelector(adsCreationSelector);
  const type = useSelector(adsCreationAudienceTypeSelector);
  const citiesWithPOS = useSelector(adsCreationCitiesWithPOSSelector);
  const user = useSelector(globalUserSelector);

  const createNewAd = useCallback((): void => {
    dispatch(globalActions.setIsLoading(true));
    const body: AdsCreationSubmitState = {
      cpgId: +creation.cpg?.id!,
      cpgName: creation.cpg?.name!,
      name: creation.detail.name,
      description: creation.detail.description,
      keywords: creation.detail.keywords,
      skus: creation.skusByCPG,
      audienceId: type === AUDIENCE_BY_CITIES_TYPE_ID ? 1 : 2,
      serials: creation.serials,
      cities: creation.citiesIds.map(item => citiesWithPOS.find(e => e.id === item)!),
      programmingId: 1,
      initDate: creation.standardProgramming.initDate,
      endDate: creation.standardProgramming.endDate,
      url: creation.videoUrl,
      agencyId: user.agencyId,
      advertisingIncome: +creation.advertisingIncome,
    }

    fetchGetSubmit(body)
      .then(() => {
        Clevertap.pushEvent("CompleteCreationPAds", { userId: user.email });
        showNotification(t('Anuncio creado con éxito.'), 'success');
        navigate(ROUTES.home);
      })
      .catch(() => {
        showNotification(t('Lo sentimos, la campaña no se ha creado'), 'error');
      })
      .finally(() => dispatch(globalActions.setIsLoading(false)))
  }, [dispatch, navigate, t, creation, type, citiesWithPOS, user]);

  return {
    createNewAd
  };
}

export default useSubmitAd;
