import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Ad } from "../entities";
import { initialState } from './home.state';

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setAds: (state, action: PayloadAction<Ad[]>) => {
      state.data.ads = action.payload
    },
    setSearchvalue: (state, { payload }: PayloadAction<string>) => {
      state.data.searchValue = payload
    },
  },
});

export const actions = homeSlice.actions;
export default homeSlice.reducer;
