import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationVideoSelector } from '@modules/ads/store';
import styles from './ads-creation-video-player.module.sass';
import useYouTubePlayer from '@hooks/use-you-tube-player';

interface AdsCreationVideoPlayerProps { }

const AdsCreationVideoPlayer: FC<AdsCreationVideoPlayerProps> = () => {
  const { t } = useTranslation();
  const video = useSelector(adsCreationVideoSelector);
  const dispatch = useDispatch();

  const onVideError = useCallback(() => {
    dispatch(actions.setIsCurrentStepCompleted(false))
  }, [dispatch]);

  const onVideoSuccess = useCallback(() => {
    dispatch(actions.setIsCurrentStepCompleted(true));
  }, [dispatch]);

  const { loadNewVideo } = useYouTubePlayer('yt-player', onVideoSuccess, onVideError);

  useEffect(() => {
    if (!!video) loadNewVideo(video);
  }, [dispatch, loadNewVideo, video]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('Previsualización del anuncio')}</div>

      <div className={styles.player}>
        <div id='yt-player' />
      </div>
    </div>
  );
}

export default AdsCreationVideoPlayer;
