import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainRouter from '@router/main.router';

function App() {

  return (
    <BrowserRouter basename="/">
      <MainRouter />
    </BrowserRouter>
  );
}

export default App;
