export const errors = {
  spanish: {
    "required": "Campo requerido.",
    "min_digits": "Mínimo {{digits}} dígitos.",
    "max_digits": "Máximo {{digits}} dígitos.",
    "max_characters": "Máximo {{max}} caracteres.",
    "only_letters": "Solo se permiten caracteres de la A a la Z",
    "only_numbers": "Solo se permiten números",
    "spaces_init": "El campo no debe comenzar con espacios",
    "invalid_phone": "Número de celular invalido",
    "passwords_no_match": "Las contraseñas no coinciden",
    "end_date": "La fecha de finalización debe ser posterior a la fecha de inicio",
    "invalid_date": "Fecha invalida",
    "invalid_email": "Email invalido",
    "overpay": "Está intentando abonar un valor mayor al de la deuda",
    "max_purchase_price": "El precio de compra no debe superar al precio de venta",
    "min_sale_price": "El precio de venta no debe ser inferior al precio de compra",
    "not_same_name": "El nombre no debe ser igual",
    "not_same_phone": "El número de celular no debe ser igual",
    "min_amount": "El monto ingresado debe ser mayor a $0",
    "max_amount": "El monto ingresado debe ser menor a {{max}}",
  },
  portuguese: {
    "required": "Campo requerido.",
    "min_digits": "Mínimo {{digits}} dígitos.",
    "max_digits": "Máximo {{digits}} dígitos.",
    "max_characters": "Máximo de {{max}} caracteres.",
    "only_letters": "Apenas caracteres de A a Z são permitidos",
    "only_numbers": "Apenas números são permitidos",
    "spaces_init": "O campo não deve começar com espaços",
    "invalid_phone": "Número de celular inválido",
    "passwords_no_match": "As senhas não coincidem",
    "end_date": "A data final deve ser maior que a data inicial",
    "invalid_date": "Data inválida",
    "invalid_email": "Email inválido",
    "overpay": "Está tentando descontar um valor maior que sua dívida",
    "max_purchase_price": "O preço de compra não deve ser maior que o de venda",
    "min_sale_price": "O preço de venda não deve ser menor que o de compra",
    "not_same_name": "O nome não deve ser igual",
    "not_same_phone": "O número de celular não deve ser igual",
    "min_amount": "O valor inserido deve ser maior a R$0",
    "max_amount": "O valor inserido deve ser inferior a {{max}}",
  }
}
