import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentedControl } from '@chiper-inc/sb-web-chiper';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationAudienceTypeSelector } from '@modules/ads/store';
import { fetchDoubleTemplate } from '@modules/ads/services';
import { AUDIENCE_BY_FILE_SERIALS_TYPE_ID, AUDIENCE_BY_MANUAL_SERIALS_TYPE_ID } from '@modules/ads/constants';
import useHandleSerials from '@modules/ads/hooks/use-handle-serials';
import styles from './ads-creation-audience-by-serial.module.sass';
import UiButton from '@ui/ui-button';
import AdsCreationAudienceSerialUpload from '../ads-creation-audience-serial-upload';
import AdsCreationAudienceSerialManual from '../ads-creation-audience-serial-manual';
import useGetSerials from '@modules/ads/hooks/use-get-serials';

interface AdsCreationAudienceBySerialProps { }

const AdsCreationAudienceBySerial: FC<AdsCreationAudienceBySerialProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const audienceType = useSelector(adsCreationAudienceTypeSelector);
  const identifiedSerials = useHandleSerials();
  const { isLoading } = useGetSerials();

  useEffect(() => {
    dispatch(actions.setIsCurrentStepCompleted(!!identifiedSerials))
  }, [dispatch, identifiedSerials]);

  const handleSegmentedControl = (i: number) => {
    dispatch(actions.setCreationState({ serials: [] }))
    dispatch(actions.setAudienceCurrentType(i === 0
      ? AUDIENCE_BY_FILE_SERIALS_TYPE_ID
      : AUDIENCE_BY_MANUAL_SERIALS_TYPE_ID
    ))
  }

  if (isLoading) return <>...</>

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.title}>{t("Ingresa los seriales de POS")}</div>
        <SegmentedControl
          options={[t("Excel"), t("Manual")]}
          initialSelected={audienceType - 2}
          controlSelected={handleSegmentedControl}
        />
      </div>

      <div className={`${styles.example} ${audienceType === AUDIENCE_BY_MANUAL_SERIALS_TYPE_ID && styles.show}`}>
        {t("Referencia de serial POS:  DA0419CF40320")}
      </div>

      <div className={styles.center}>
        <div className={styles.total}>
          ({identifiedSerials})
          <span className={styles.text}>{t(" Seriales identificados")}</span>
        </div>
        {audienceType === AUDIENCE_BY_FILE_SERIALS_TYPE_ID && (
          <UiButton uiType='secondary' size='medium' onClick={fetchDoubleTemplate} hasFitWidth>
            {t('Descargar plantilla')}
          </UiButton>
        )}
      </div>

      {audienceType === AUDIENCE_BY_FILE_SERIALS_TYPE_ID
        ? <AdsCreationAudienceSerialUpload />
        : <AdsCreationAudienceSerialManual />
      }
    </div>
  );
}

export default AdsCreationAudienceBySerial;
