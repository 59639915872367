import { createSelector } from '@reduxjs/toolkit';
import { GlobalUserState, RootState } from '@config/store';
import { AdAudience, AdState, Notification } from '@entities';

export const globalUiNotificationSelector = createSelector(
  (state: RootState) => state.global.ui.notification,
  (state: Notification) => state
);

export const globalUiLoaderSelector = createSelector(
  (state: RootState) => state.global.ui.isLoading,
  (state: boolean) => state
);

export const globalUserSelector = createSelector(
  (state: RootState) => state.global.data.user,
  (state: GlobalUserState) => state
);

export const globalOnboardingsSelector = createSelector(
  (state: RootState) => state.global.data.onboardingIdsDone,
  (state: number[]) => state
);

export const globalAdStatesSelector = createSelector(
  (state: RootState) => state.global.data.adStates,
  (state: AdState[]) => state
);

export const globalAdAdminStatesSelector = createSelector(
  (state: RootState) => state.global.data.adAdminStates,
  (state: AdState[]) => state
);

export const globalAdAudiencesSelector = createSelector(
  (state: RootState) => state.global.data.adAudiences,
  (state: AdAudience[]) => state
);
