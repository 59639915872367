import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponseItem } from "@entities";
import { actions as globalActions } from '@config/store';
import { fetchGetCPG } from "@modules/ads/services";
import { actions, adsCreationCGPSelector } from "../store";

const useSearchCGPs = () => {
  const dispatch = useDispatch();
  const [results, setResults] = useState<ResponseItem[] | null>(null)
  const currentCPG = useSelector(adsCreationCGPSelector)?.name;

  const searchCGP = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setCreationState({ cpg: null }));
    if (!e.target.value) setResults(null)
    else {
      dispatch(globalActions.setIsLoading(true));
      fetchGetCPG(e.target.value)
        .then(data => {
          const newCGP = {
            id: 0,
            imageUrl: '',
            name: e.target.value,
          }
          setResults([newCGP, ...data])
        })
        .catch(() => setResults([]))
        .finally(() => dispatch(globalActions.setIsLoading(false)));
    }
  }, [dispatch])

  const selectCPG = (cpg: ResponseItem) => {
    dispatch(actions.setCreationState({ cpg }));
    setResults(null);
  }

  return {
    searchCGP,
    results,
    selectCPG,
    currentCPG
  };
}

export default useSearchCGPs;
