import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/ads/store';
import { ADS_CREATION_DEFINITION_STEP } from '@modules/ads/constants';
import AdsCreationDefinitionSkus from '@modules/ads/components/ads-creation-definition-skus';
import AdsCreationDefinitionManager from '@modules/ads/components/ads-creation-definition-manager';
import AdsCreationDefinitionDetails from '@modules/ads/components/ads-creation-definition-details';
import AdsCreationDefinitionSearchBar from '@modules/ads/components/ads-creation-definition-search-bar';
import styles from './ads-creation-definition.module.sass';
import AdsCreationDefinitionAdvertisingIncome from '@modules/ads/components/ads-creation-definition-advertising-income';

interface AdsCreationDefinitionProps { }

const AdsCreationDefinition: FC<AdsCreationDefinitionProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setCurrentCreationStep(ADS_CREATION_DEFINITION_STEP))
  }, [dispatch]);

  return (
    <>
      <div className={styles.container}>

        <div className={styles.left}>
          <AdsCreationDefinitionSearchBar />
          <AdsCreationDefinitionDetails />
        </div>
        <div className={styles.right}>
          <AdsCreationDefinitionAdvertisingIncome />
          <AdsCreationDefinitionSkus />
        </div>
      </div>

      <AdsCreationDefinitionManager />
    </>
  );
}

export default AdsCreationDefinition;
