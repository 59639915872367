import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Icon, Theme } from '@chiper-inc/sb-web-chiper';
import UiDatePicker from '@ui/ui-date-picker';
import styles from './ui-input-date.module.sass';
import useOutsideClickDetector from '@hooks/use-outside-click-detector';

interface UiInputDateProps {
  value?: Date;
  onChange: (d: Date) => void;
  label?: string;
  formatShow?: string;
  width?: string;
  left?: string;
  right?: string;
  isDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isReadOnly?: boolean;
}

const UiInputDate: FC<UiInputDateProps> = ({
  value,
  onChange,
  label,
  formatShow = 'dd/MM/yyyy',
  width,
  left,
  right,
  isDisabled,
  minDate,
  maxDate,
  isReadOnly,
}) => {
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClickDetector(ref, () => setIsOnFocus(false));

  const handleDateChange = useCallback((date: Date) => {
    setIsOnFocus(false)
    onChange(date);
  }, [onChange]);

  const handleFocus = () => {
    if (!isDisabled && !isReadOnly) setIsOnFocus(prev => !prev)
  }

  return (
    <div className={styles.container} ref={ref}>
      <div
        className={`
        ${styles.wrapper} 
        ${isDisabled && styles.disabled}
        ${isOnFocus && styles.focus}
        ${isReadOnly && styles.readOnly}
      `}
        onClick={handleFocus}
        data-testid='ui-input-date'
        style={{ width }}
      >
        <Icon name='CalendarBlank' size={16} color={Theme.colors.primary.darkBlueGray} />
        <div className={styles.input}>
          <div className={`${styles.label} ${(!!value || isOnFocus) && styles.top}`}>
            {label}
          </div>
          {!!value && (
            <div className={styles.value}>
              {format(value, formatShow)}
            </div>
          )}
        </div>
      </div>
      <div className={styles.picker} style={{ left, right }}>
        {isOnFocus && (
          <UiDatePicker
            onClick={handleDateChange}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </div>
    </div>
  );
}

export default memo(UiInputDate);
