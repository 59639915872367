export const country = {
  colombia: {
    "area_code": "+57",
    "phone_length": "10"
  },
  mexico: {
    "area_code": "+52",
    "phone_length": "10"
  },
  brazil: {
    "area_code": "+55",
    "phone_length": "11"
  }
};
