import React, { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions, adsCreationAdvertisingIncomeSelector } from "@modules/ads/store";
import UiInputField from "@ui/ui-input-field";
import styles from "./ads-creation-definition-advertising-income.module.sass";

interface AdsCreationDefinitionAdvertisingIncomeProps { }

const AdsCreationDefinitionAdvertisingIncome: FC<AdsCreationDefinitionAdvertisingIncomeProps> = () => {
  const dispatch = useDispatch();
  const advertisingIncome = useSelector(adsCreationAdvertisingIncomeSelector);
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(actions.setCreationState({ advertisingIncome: e.target.value } ));
  };

  
  const regex = /^[1-9]\d*$/; // Only positive integers
  const isValid = regex.test(advertisingIncome);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>&nbsp;</div>

      <UiInputField
        label={t("Ingreso por pauta")}
        name="advertisingIncome"
        placeholder={t("Ingreso por pauta")}
        value={advertisingIncome}
        onChange={handleChange}
        maxCharacters={10}
        hasError={!!advertisingIncome && !isValid}
        error="El ingreso por pauta debe ser un número entero mayor a 0"
      />

    </div>
  );
};

export default AdsCreationDefinitionAdvertisingIncome;
