import React, { FC } from "react";
import styles from "./admin-users.module.sass";
import AdminTop from "@modules/admin/components/admin-top/admin-top";
import AdminUsersTable from "@modules/admin/components/admin-users-table";
import { useNavigate } from "react-router-dom";
import { ADMIN_TABS } from "@modules/admin/constants";
import usePermissions from "@hooks/use-permissions";

interface AdminUsersProps { }

const AdminUsers: FC<AdminUsersProps> = () => {
  const navigate = useNavigate();
  const { canCreateUsers } = usePermissions();

  return (
    <div className={styles.wrapper}>

      <AdminTop
        placeholder="Busca por correo electrónico"
        buttonText="Crear usuario"
        onClick={() => navigate(ADMIN_TABS.creation)}
        hasDisabledButton={!canCreateUsers()}
      />

      <AdminUsersTable />

    </div>
  );
}

export default AdminUsers;
