import * as yup from "yup";
import { t } from "i18next";
import { MAIL_REG_EXP, REG_EXP_NUMBERS_WITH_TRAITS } from "@constants";

export const agencyCreationSchema = yup.object().shape({
  name: yup.string()
    .required(t("Campo requerido"))
    .max(60, t("Maximo 60 digitos")),
  adminName: yup.string()
    .required(t("Campo requerido"))
    .max(60, t("Maximo 60 caracteres")),
  taxNumber: yup.string()
    .required(t("Campo requerido"))
    .matches(REG_EXP_NUMBERS_WITH_TRAITS, t("Solo se pueden numeros")),
  adminEmail: yup.string()
    .required(t("Campo requerido"))
    .matches(MAIL_REG_EXP, t("El email no es valido")),
});
