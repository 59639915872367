import { Ad } from "../entities";

export interface HomeState {
  ui: HomeUiState;
  data: HomeDataState;
}

export interface HomeUiState { }

export interface HomeDataState {
  ads: Ad[];
  searchValue: string;
}

export const initialState: HomeState = {
  ui: {},
  data: {
    ads: [],
    searchValue: ''
  }
}
