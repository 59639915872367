import { fetchGetAds } from "@modules/home/services";
import { actions } from "@modules/home/store";
import { sortDscNumberFn } from "@utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Ad } from "../entities";
import { globalUserSelector } from "@config/store";
import usePermissions from "@hooks/use-permissions";

const useGetAds = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useSelector(globalUserSelector)
  const { canSeeAllCampaings } = usePermissions();

  const agencyByPermission = canSeeAllCampaings() ? undefined : user.agencyId;

  useEffect(() => {
    setIsLoading(true);
    fetchGetAds(agencyByPermission)
      .then(data => {
        const sortFn = (a: Ad, b: Ad) => sortDscNumberFn(a.id, b.id);
        dispatch(actions.setAds([...data].sort(sortFn)));
      })
      .catch(() => true)
      .finally(() => setIsLoading(false))
  }, [dispatch, agencyByPermission]);

  return { isLoading }
}

export default useGetAds;
