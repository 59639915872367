import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actions as globalActions } from '@config/store';
import { UserForm } from "../entities";
import { fetchCreateUser } from "../services";
import { showNotification } from "@utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@constants";
import { useTranslation } from "react-i18next";

const useUserCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createNewUser = useCallback((userForm: UserForm): void => { 
    dispatch(globalActions.setIsLoading(true));
    const body: UserForm = {
      name: userForm.name,
      email: userForm.email,
      phone: userForm.phone,
      agencyId: userForm.agencyId,
      areaCode: userForm.areaCode,
      roleId: userForm.roleId
    }

    fetchCreateUser(body)
      .then(() => {
        showNotification(t('Usuario creado exitosamente.'), 'success');
        navigate(ROUTES.admin)
      })
      .catch(() => {
        showNotification(t('Lo sentimos, el usuario no se ha creado'), 'error');
      })
      .finally(() => {
        dispatch(globalActions.setIsLoading(false));
      })
  }, [dispatch, navigate, t]);

  return {
    createNewUser
  }
}

export default useUserCreation;
