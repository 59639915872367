import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdsCreationState, initialState } from './ads.state';
import { CREATION_INITIAL_STATE } from "../constants";

export const adsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    setCurrentCreationStep: (state, action: PayloadAction<number>) => {
      state.ui.currentCreationStep = action.payload
    },
    setIsSkusFieldDisabled: (state, action: PayloadAction<boolean>) => {
      state.ui.isSkusFieldDisabled = action.payload
    },
    setIsCurrentStepCompleted: (state, action: PayloadAction<boolean>) => {
      state.ui.isCurrentStepCompleted = action.payload
    },
    setAudienceCurrentType: (state, action: PayloadAction<number>) => {
      state.ui.audienceCurrentType = action.payload
    },
    setProgrammingCurrentType: (state, action: PayloadAction<number>) => {
      state.ui.programmingCurrentType = action.payload
    },
    setPOSSerials: (state, action: PayloadAction<string[]>) => {
      state.ui.posSerials = action.payload
    },
    setCreationState: (state, action: PayloadAction<Partial<AdsCreationState>>) => {
      state.data.creation = { ...state.data.creation, ...action.payload }
    },
    restartCreationState: (state,) => {
      state.data.creation = CREATION_INITIAL_STATE
    },
  },
});

export const actions = adsSlice.actions;
export default adsSlice.reducer;
