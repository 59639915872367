import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import usePermissions from "@hooks/use-permissions";
import useGetAgencies from "@modules/admin/hooks/use-get-agencies";
import useSortTable from "@modules/admin/hooks/use-sort-table";
import { globalAdAdminStatesSelector } from "@config/store";
import { adsAdminAgenciesSelector, adsAdminSearchValueSelector } from "@modules/admin/store";
import { ADMIN_AGENCIES_TABLE } from "@modules/admin/constants";
import styles from "./admin-agencies-table.module.sass";
import { Icon, Tag } from "@chiper-inc/sb-web-chiper";

interface AdminAgenciesTableProps {}

const AdminAgenciesTable: FC<AdminAgenciesTableProps> = () => {
  const { t } = useTranslation();
  const { sortByAgenciesState, activeColumn } = useSortTable();
  const { isLoading } = useGetAgencies();
  const { canUpdateAgencies, canDeleteAgencies } = usePermissions();
  const agencies = useSelector(adsAdminAgenciesSelector);
  const adminStates = useSelector(globalAdAdminStatesSelector);
  const searchValue = useSelector(adsAdminSearchValueSelector);
  const activeIcons = false;

  const getStateProps = (id: number) => {
    const state = adminStates.find((el) => el.id === id)!;
    return { text: t(state.key), skin: state.color };
  };

  const list = useMemo(() => (
    agencies.filter((item) => item.agencyName.toLowerCase().includes(searchValue.toLowerCase().trim())
  )),[agencies, searchValue]);

  const handleEditionClick = () => {
    if (canUpdateAgencies()) { console.log() }
  };

  const handleDeletionClick = () => {
    if (canDeleteAgencies()) { console.log() }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {ADMIN_AGENCIES_TABLE.map((item) => (
          <div
            className={`${styles.head} ${activeColumn === 2 && styles.active}`}
            key={`table-head-${item.title}`}
          >
            {t(item.title)}
            {!!item.icon && (
              <div
                className={styles.icon}
                onClick={() => sortByAgenciesState()}
                data-testid="table-head-item"
              >
                <Icon size="16px" weight="bold" name={item.icon} />
              </div>
            )}
          </div>
        ))}
      </div>

      {isLoading
        ? <div>...</div>
        : list.map((agency) => (
          <div className={styles.row} key={`table-id-${agency.id}`}>
            <div className={styles.cell}>{agency.agencyName}</div>
            <div className={styles.cell}>{agency.adminEmail}</div>
            <div className={styles.cell}>{agency.usersCount}</div>

            <div className={styles.cell}>
              <Tag {...getStateProps(agency.stateId)} />
            </div>

            <div className={`${styles.cell} ${styles.actions}`}>
              <div
                className={`
                  ${styles.icon} 
                  ${!activeIcons || !canUpdateAgencies() ? styles.disabled : ""}
                `}
                onClick={handleEditionClick}
                data-testid='edit-icon'
              >
                <Icon name="PencilSimple" size={16} />
              </div>
              <div
                className={`
                  ${styles.icon} 
                  ${!activeIcons || !canDeleteAgencies() ? styles.disabled : ""}
                `}
                onClick={handleDeletionClick}
                data-testid='delete-icon'
              >
                <Icon name="Trash" size={16} />
              </div>
            </div>
          </div>
      ))}
    </div>
  );
};

export default AdminAgenciesTable;
