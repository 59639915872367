import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponseItem, ResponseItemIdType } from "@entities";
import { actions as globalActions } from '@config/store';
import { SkuResponseItem } from "../entities/ads.entities";
import { fetchGetSKUsByCPG } from "../services";
import { actions, adsCreationCGPSelector, adsCreationSKUsByCGPSelector } from "../store";


const useSearchSKUs = () => {
  const dispatch = useDispatch();
  const [results, setResults] = useState<SkuResponseItem[] | null>(null)
  const cpg = useSelector(adsCreationCGPSelector);
  const skus = useSelector(adsCreationSKUsByCGPSelector);

  const searchSKUs = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) setResults(null)
    else {
      dispatch(globalActions.setIsLoading(true));
      fetchGetSKUsByCPG(cpg?.id || 0, e.target.value)
        .then(data => setResults(data))
        .catch(() => setResults([]))
        .finally(() => dispatch(globalActions.setIsLoading(false)));
    }
  }, [cpg?.id, dispatch])

  const selectSKU = (sku: ResponseItem) => {
    if (!skus.find(e => e.sku === sku.id)) {
      dispatch(actions.setCreationState({ skusByCPG: [...skus, { ...sku, sku: String(sku.id) }] }));
    }
    setResults(null);
  }

  const removeSKU = (sku: ResponseItemIdType) => {
    dispatch(actions.setCreationState({ skusByCPG: skus.filter(e => e.sku !== sku) }));
  }

  return {
    searchSKUs,
    results,
    selectSKU,
    removeSKU
  };
}

export default useSearchSKUs;
