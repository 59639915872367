import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { userCreationSchema } from "@modules/admin/schemas/user-creation-schemas";
import { adsAdminAgenciesSelector, adsAdminRolesSelector } from "@modules/admin/store";
import useUserCreation from "@modules/admin/hooks/use-user-creation";
import { USER_FORM } from "@modules/admin/constants";
import { COUNTRIES } from "@constants";
import { Selector } from "@entities";
import styles from "./admin-user-form.module.sass";
import { SelectField, TextField } from "@chiper-inc/sb-web-chiper";
import UiInputField from "@ui/ui-input-field";
import UiButton from "@ui/ui-button";

interface AdminUserFormProps { }

const AdminUserForm: FC<AdminUserFormProps> = () => {
  const [rolesOptions, setRolesOptions] = useState<Selector[]>([]);
  const [agenciesOptions, setAgencyOptions] = useState<Selector[]>([]);
  const { t } = useTranslation();
  const { createNewUser } = useUserCreation();
  const roles = useSelector(adsAdminRolesSelector);
  const agencies = useSelector(adsAdminAgenciesSelector);

  useEffect(() => {
    setRolesOptions(
      roles.map((i) => ({ text: i.roleName, value: String(i.id) }))
    );
    setAgencyOptions(
      agencies.map((i) => ({ text: i.agencyName, value: String(i.id) }))
    );
  }, [agencies, roles]);

  const handleSubmit = () => {
    createNewUser(form.values);
  };

  const form = useFormik({
    initialValues: USER_FORM,
    validationSchema: userCreationSchema,
    onSubmit: handleSubmit,
  });

  const handleSelectOption = async (itemSelected: string, name?: string) => {
    const numberValidation = Number(itemSelected);

    await form.setFieldValue(name!, numberValidation || 0);
  };

  const handleCodeAreaChange = (areaCode: number) => {
    form.setFieldValue("areaCode", areaCode);
  };

  return (
    <form className={styles.wrapper} onSubmit={form.handleSubmit}>
      <div className={styles.left}>
        <div className={styles.title}>{t("Datos del perfil")}</div>
        <div className={styles.subtitle}>
          {t("Escribe el nombre de usuario")}
        </div>
        <TextField
          value={form.values.name}
          name="name"
          placeholder={t("Datos del perfil")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.name || !form.touched.name}
          helperText={!form.touched.name ? undefined : form.errors.name}
          type="text"
          full
          autoComplete="off"
        />

        <div className={styles.subtitle}>
          {t("Escribe el correo del usuario")}
        </div>
        <TextField
          value={form.values.email}
          name="email"
          placeholder={t("Correo electrónico")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          isValid={!form.errors.email || !form.touched.email}
          helperText={!form.touched.email ? undefined : form.errors.email}
          type="text"
          full
          autoComplete="off"
        />

        <div className={styles.title}>{t("Datos de contacto")}</div>
        <div className={styles.subtitle}>
          {t("Escribe el número de celular del usuario")}
        </div>
        <UiInputField
          value={form.values.phone}
          name="phone"
          placeholder={t("Número de celular")}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          hasError={!!form.errors.phone && form.touched.phone}
          error={form.errors.phone}
          type="text"
          isPhone
          countries={COUNTRIES}
          onCountrySelect={handleCodeAreaChange}
          countrySelected={form.values.areaCode}
        />
      </div>

      <div className={styles.right}>
        <div className={styles.title}>{t("Datos de la agencia")}</div>
        <div className={styles.subtitle}>
          {t("Selecciona la agencia a la que pertenece el usuario")}
        </div>
        <SelectField
          options={agenciesOptions}
          onChange={handleSelectOption}
          value={String(form.values.agencyId)}
          name="agencyId"
          placeholder={t("Nombre de la agencia")}
          full
        />
        <div className={styles.title}>{t("Datos del rol")}</div>
        <div className={styles.subtitle}>
          {t("Selecciona el rol del usuario")}
        </div>
        <SelectField
          options={rolesOptions}
          onChange={handleSelectOption}
          value={String(form.values.roleId)}
          name="roleId"
          placeholder={t("Rol del usuario")}
          full
        />
      </div>

      <div className={styles.submit}>
        <UiButton
          type="submit"
          size="large"
          width="188px"
          isDisabled={!form.isValid || !form.dirty}
        >
          {t("Crear")}
        </UiButton>
      </div>
    </form>
  );
};

export default AdminUserForm;
