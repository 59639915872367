import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AUDIENCE_BY_CITIES_TYPE_ID, AUDIENCE } from '@modules/ads/constants';
import { adsCreationAudienceTypeSelector, adsCreationCitiesWithPOSSelector, adsCreationSelector } from '@modules/ads/store';
import styles from './ads-creation-verification-fields-info.module.sass';
import UiInputField from '@ui/ui-input-field/ui-input-field';

interface AdsCreationVerificationFieldsInfoProps { }

const AdsCreationVerificationFieldsInfo: FC<AdsCreationVerificationFieldsInfoProps> = () => {
  const { t } = useTranslation();
  const data = useSelector(adsCreationSelector);
  const audienceType = useSelector(adsCreationAudienceTypeSelector)
  const citiesWithPOS = useSelector(adsCreationCitiesWithPOSSelector);
  const getAudienceItems = () => {
    return audienceType === AUDIENCE_BY_CITIES_TYPE_ID
      ? citiesWithPOS.reduce((prev, act) => data.citiesIds.includes(act.id) ? act.name.concat(' ' + prev) : prev, '')
      : data.serials.reduce((prev, act) => act.concat(' ' + prev))
  }

  return (
    <div className={styles.wrapper}>

      <div className={styles.form}>
        <div className={styles.title}>{t('CPG')}</div>
        <UiInputField
          label={t('Nombre del CPG')}
          value={data.cpg?.name}
        />
      </div>

      <div className={styles.form}>
        <div className={styles.title}>{t('Anuncio')}</div>
        <UiInputField
          label={t('Nombre del anuncio')}
          value={data.detail.name}
          isReadOnly
        />
      </div>

      <div className={styles.form}>
        <div className={styles.title}>{t('Audiencia')}</div>
        <UiInputField
          label={t('Tipo de audiencia')}
          value={t(AUDIENCE.get(audienceType) || '')}
          isReadOnly
        />
        <UiInputField
          label={t('Items de la audiencia')}
          value={getAudienceItems()}
          isReadOnly
        />
      </div>

    </div>
  )
}

export default AdsCreationVerificationFieldsInfo;