import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import usePermissions from "@hooks/use-permissions";
import { ADMIN_TABS } from "@modules/admin/constants";
import styles from "./admin-agencies.module.sass";
import AdminTop from "@modules/admin/components/admin-top";
import AdminAgenciesTable from '@modules/admin/components/admin-agencies-table';

interface AdminAgenciesProps {}

const AdminAgencies: FC<AdminAgenciesProps> = () => {
  const navigate = useNavigate();
  const { canCreateAgencies } = usePermissions();

  return (
    <div className={styles.wrapper}>
      
      <AdminTop
        placeholder="Busca por nombre de agencia"
        buttonText="Crear agencias"
        onClick={() => navigate(ADMIN_TABS.creation)}
        hasDisabledButton={!canCreateAgencies()}
      />

      <AdminAgenciesTable />

    </div>
  );
};

export default AdminAgencies;
