import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, adsAdminAgenciesSelector, adsAdminRolesSelector, adsAdminUsersSelector } from "../store";
import { sortAscStringFn, sortDscStringFn } from "@utils";
import { useTranslation } from "react-i18next";
import { globalAdAdminStatesSelector } from "@config/store";

const useSortTable = () => {
  const [activeColumn, setActiveColumn] = useState<number>(-1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const users = useSelector(adsAdminUsersSelector);
  const roles = useSelector(adsAdminRolesSelector);
  const agencies = useSelector(adsAdminAgenciesSelector);
  const adminStates = useSelector(globalAdAdminStatesSelector);
  const isSortAsc = useRef<boolean>(false);

  const sortList = (infoArray: any, sortFn: (a: string, b: string) => number) => {
    return [...infoArray].sort((a, b) => sortFn(
      t(adminStates.find(e => e.id === a.stateId)!.key),
      t(adminStates.find(e => e.id === b.stateId)!.key)
    ));
  }

  const sortByRolesState = () => {
    setActiveColumn(1);
    isSortAsc.current = !isSortAsc.current
    const sortFn = isSortAsc.current ? sortAscStringFn : sortDscStringFn;

    dispatch(actions.setRoles(sortList(roles, sortFn)));
  };

  const sortByAgenciesState = () => {
    setActiveColumn(2);
    isSortAsc.current = !isSortAsc.current
    const sortFn = isSortAsc.current ? sortAscStringFn : sortDscStringFn;

    dispatch(actions.setAgencies(sortList(agencies, sortFn)));
  };

  const sortByUsersAgency = () => {
    setActiveColumn(3);
    isSortAsc.current = !isSortAsc.current
    const sortFn = isSortAsc.current ? sortAscStringFn : sortDscStringFn;

    let array = [...users].sort((a, b) => sortFn(a.agencyName, b.agencyName));
    dispatch(actions.setUsers(array));
  };

  const sortByUsersState = () => {
    setActiveColumn(5)
    isSortAsc.current = !isSortAsc.current
    const sortFn = isSortAsc.current ? sortAscStringFn : sortDscStringFn;

    dispatch(actions.setUsers(sortList(users, sortFn)))
  };

  return {
    sortByRolesState,
    sortByUsersState,
    sortByUsersAgency,
    sortByAgenciesState,
    activeColumn
  };
}

export default useSortTable;
