import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ADMIN_HOME_TABS, ADMIN_TABS } from '../constants';
import { ROUTES } from '@constants';
import AdminUsers from '../pages/admin-users/admin-users';
import AdminHomeContainer from '../components/admin-home-container';
import AdminRoles from '../pages/admin-roles';
import AdminUserCreation from '../pages/admin-user-creation';
import AdminAgencies from '../pages/admin-agencies';
import AdminAgencyCreation from '../pages/admin-agency-creation';

interface AdminRouterProps { }

const AdminRouter: FC<AdminRouterProps> = () => {

  return (
    <Routes>
      <Route path={ADMIN_TABS.home} element={<AdminHomeContainer />}>
        <Route index element={<Navigate to={ADMIN_HOME_TABS.users} />} />
        <Route path={ADMIN_HOME_TABS.users} element={<AdminUsers />} />
        <Route path={ADMIN_HOME_TABS.roles} element={<AdminRoles />} />
        <Route path={ADMIN_HOME_TABS.agencies} element={<AdminAgencies />} />
        <Route path="*" element={<Navigate to={ADMIN_HOME_TABS.users} />} />
      </Route>

        <Route path={ADMIN_TABS.userCreation} element={<AdminUserCreation />} />
        <Route path={ADMIN_TABS.agencyCreation} element={<AdminAgencyCreation />} />

      <Route path="*" element={<Navigate to={ROUTES.admin} />} />

    </Routes>
  );
}

export default AdminRouter;
