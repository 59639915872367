import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '@modules/ads/store';
import { ADS_CREATION_FORMAT_STEP } from '@modules/ads/constants';
import AdsCreationFormatForm from '@modules/ads/components/ads-creation-format-form';
import AdsCreationVideoPlayer from '@modules/ads/components/ads-creation-video-player';
import styles from './ads-creation-format.module.sass';

interface AdsCreationFormatProps { }

const AdsCreationFormat: FC<AdsCreationFormatProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.setCurrentCreationStep(ADS_CREATION_FORMAT_STEP));
    dispatch(actions.setIsCurrentStepCompleted(false));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <AdsCreationFormatForm />

      <AdsCreationVideoPlayer />
    </div>
  );
}

export default AdsCreationFormat;
