import { Countries } from '@entities';
import ArgentinaFlag from '@img/argentina-flag.png';
import ArubaFlag from '@img/aruba-flag.png';
import BoliviaFlag from '@img/bolivia-flag.png';
import BrasilFlag from '@img/brasil-flag.png';
import ChileFlag from  '@img/chile-flag.png';
import ColombiaFlag from '@img/colombia-flag.png';
import EcuadorFlag from '@img/ecuador-flag.png';
import EspanaFlag from '@img/espana-flag.png';
import GuyanaFlag from '@img/guyana-flag.png';
import MexicoFlag from '@img/mexico-flag.png';
import ParaguayFlag from '@img/paraguay-flag.png';
import PeruFlag from '@img/peru-flag.png';
import SurinameFlag from '@img/suriname-flag.png';
import TrindadeTobagoFlag from '@img/trindade-tobago-flag.png';
import UnitedStatesFlag from '@img/united-states-flag.png';
import UruguayFlag from '@img/uruguay-flag.png';
import VenezuelaFlag from '@img/venezuela-flag.png';

export const COUNTRIES: Countries[] = [
  { img: UnitedStatesFlag, code: 1 },
  { img: TrindadeTobagoFlag, code: 1 },
  { img: EspanaFlag, code: 34 },
  { img: PeruFlag, code: 51 },
  { img: MexicoFlag, code: 52 },
  { img: ArgentinaFlag, code: 54 },
  { img: BrasilFlag, code: 55 },
  { img: ChileFlag, code: 56 },
  { img: ColombiaFlag, code: 57 },
  { img: VenezuelaFlag, code: 58 },
  { img: ArubaFlag, code: 297 },
  { img: BoliviaFlag, code: 591 },
  { img: GuyanaFlag, code: 592 },
  { img: EcuadorFlag, code: 593 },
  { img: ParaguayFlag, code: 595 },
  { img: SurinameFlag, code: 597 },
  { img: UruguayFlag, code: 598 },
];
