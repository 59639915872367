import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon, Tag } from "@chiper-inc/sb-web-chiper";
import { adsAdminSearchValueSelector, adsAdminUsersSelector } from "@modules/admin/store";
import { globalAdAdminStatesSelector } from "@config/store";
import usePermissions from "@hooks/use-permissions";
import { ADMIN_USERS_TABLE } from "@modules/admin/constants";
import useGetUsers from "@modules/admin/hooks/use-get-users";
import useSortTable from "@modules/admin/hooks/use-sort-table";
import styles from "./admin-users-table.module.sass";

interface AdminUsersTableProps { }

const AdminUsersTable: FC<AdminUsersTableProps> = () => {
  const { t } = useTranslation();
  const users = useSelector(adsAdminUsersSelector);
  const adminStates = useSelector(globalAdAdminStatesSelector);
  const searchValue = useSelector(adsAdminSearchValueSelector);
  const { isLoading } = useGetUsers();
  const { sortByUsersAgency, sortByUsersState, activeColumn } = useSortTable();
  const { canUpdateUsers, canDeleteUsers } = usePermissions();
  const activeIcons = false;

  const getStateProps = (id: number) => {
    const state = adminStates.find(el => el.id === id)!
    return { text: t(state.key), skin: state.color }
  };

  const list = useMemo(() => (
    users.filter(item => item.email.toLowerCase().includes(searchValue.toLowerCase().trim()))
  ), [users, searchValue]);

  const handleSortClick = (i: number) => {
    if (i === 3) return sortByUsersAgency()
    return sortByUsersState()
  };

  const handleEditionClick = () => {
    if (canUpdateUsers()) { console.log() }
  }

  const handleDeletionClick = () => {
    if (canDeleteUsers()) { console.log() }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {ADMIN_USERS_TABLE.map((col, i) => (
          <div className={`${styles.head} ${activeColumn === i ? styles.active : ""}`} key={`table-head-${col.title}`}>
            {t(col.title)}
            {!!col.icon && (
              <div className={styles.icon} onClick={() => handleSortClick(i)} data-testid="table-head-item">
                <Icon size="16px" weight="bold" name={col.icon} />
              </div>
            )}
          </div>
        ))}
      </div>

      {isLoading
        ? <div>...</div>
        : list.map(user => (
          <div className={styles.row} key={`table-id-${user.id}`}>
            <div className={styles.cell}>{user.name}</div>
            <div className={styles.cell}>{user.email}</div>
            <div className={styles.cell}>{user.phone}</div>
            <div className={styles.cell}>{user.agencyName}</div>
            <div className={styles.cell}>{user.adsCount}</div>

            <div className={styles.cell}>
              <Tag {...getStateProps(user.stateId)} />
            </div>

            <div className={`${styles.cell} ${styles.actions}`}>
              <div
                className={`${styles.icon} ${!activeIcons || !canUpdateUsers() ? styles.disabled : ""}`}
                onClick={handleEditionClick}
                data-testid='edit-icon'
              >
                <Icon name="PencilSimple" size={16} />
              </div>
              <div
                className={`${styles.icon} ${!activeIcons || !canDeleteUsers() ? styles.disabled : ""}`}
                onClick={handleDeletionClick}
                data-testid='delete-icon'
              >
                <Icon name="Trash" size={16} />
              </div>
            </div>

          </div>
        ))
      }
    </div>
  );
}

export default AdminUsersTable;
