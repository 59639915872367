import { useSelector } from "react-redux";
import { globalUserSelector } from "@config/store";
import {
  USER_DELETION_PERMISSION_ID, USER_UPDATE_PERMISSION_ID, ROLE_DELETION_PERMISSION_ID,
  ROLE_CREATION_PERMISSION_ID, ROLE_UPDATE_PERMISSION_ID, CAMPAING_APPROVAL_PERMISSION_ID,
  AGENCY_CREATION_PERMISSION_ID, AGENCY_UPDATE_PERMISSION_ID, USER_CREATION_PERMISSION_ID,
  CAMPAING_CREATION_PERMISSION_ID, CAMPAING_DELETION_PERMISSION_ID, CAMPAING_UPDATE_PERMISSION_ID,
  AGENCY_DELETION_PERMISSION_ID, SEE_ALL_CAMPAINGS_PERMISSION_ID, SEE_AGENCY_CAMPAINGS_PERMISSION_ID
} from "@constants";

const usePermissions = () => {
  const user = useSelector(globalUserSelector);

  const checkPermission = (permission: number) => user.permissionsId.includes(permission);

  const canCreateCampaings = () => checkPermission(CAMPAING_CREATION_PERMISSION_ID);
  const canUpdateCampaings = () => checkPermission(CAMPAING_UPDATE_PERMISSION_ID);
  const canDeleteCampaings = () => checkPermission(CAMPAING_DELETION_PERMISSION_ID);
  const canApproveCampaings = () => checkPermission(CAMPAING_APPROVAL_PERMISSION_ID);

  const canCreateUsers = () => checkPermission(USER_CREATION_PERMISSION_ID);
  const canUpdateUsers = () => checkPermission(USER_UPDATE_PERMISSION_ID);
  const canDeleteUsers = () => checkPermission(USER_DELETION_PERMISSION_ID);

  const canCreateRoles = () => checkPermission(ROLE_CREATION_PERMISSION_ID);
  const canUpdateRoles = () => checkPermission(ROLE_UPDATE_PERMISSION_ID);
  const canDeleteRoles = () => checkPermission(ROLE_DELETION_PERMISSION_ID);

  const canCreateAgencies = () => checkPermission(AGENCY_CREATION_PERMISSION_ID);
  const canUpdateAgencies = () => checkPermission(AGENCY_UPDATE_PERMISSION_ID);
  const canDeleteAgencies = () => checkPermission(AGENCY_DELETION_PERMISSION_ID);

  const canSeeAllCampaings = () => checkPermission(SEE_ALL_CAMPAINGS_PERMISSION_ID);
  const canSeeYourAgencyCampaings = () => checkPermission(SEE_AGENCY_CAMPAINGS_PERMISSION_ID);

  return {
    canCreateCampaings,
    canUpdateCampaings,
    canDeleteCampaings,
    canApproveCampaings,
    canCreateUsers,
    canUpdateUsers,
    canDeleteUsers,
    canCreateRoles,
    canUpdateRoles,
    canDeleteRoles,
    canCreateAgencies,
    canUpdateAgencies,
    canDeleteAgencies,
    canSeeAllCampaings,
    canSeeYourAgencyCampaings,
  };
}

export default usePermissions;
