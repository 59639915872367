import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import './config/translations/i18n';
import './config/axios/axios-config';
import App from './App';
import { Provider } from 'react-redux';
import { store } from '@config/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<div />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
