import React, { FC, memo } from 'react';
import { Icon, Theme } from '@chiper-inc/sb-web-chiper';
import styles from './ui-tutorial-tooltip.module.sass';

interface PositionStyles {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

interface UiTutorialTooltipProps {
  title: string;
  text: string;
  onCloseClick?: () => void;
  onNextClick: () => void;
  onBackClick: () => void;
  totalSteps?: number;
  currentStep?: number;
  arrowPosition: 'top' | 'bottom' | 'right' | 'left';
  arrowPositionStyles?: PositionStyles;
  hideBackButtonOnFirstStep?: boolean;
  id?: string;
}

const UiTutorialTooltip: FC<UiTutorialTooltipProps> = ({
  title,
  text,
  onCloseClick,
  onNextClick,
  onBackClick,
  totalSteps = 1,
  currentStep = 1,
  arrowPosition,
  arrowPositionStyles,
  hideBackButtonOnFirstStep,
  id,
}) => {
  const hideBackButton = () => currentStep === 1 && hideBackButtonOnFirstStep;

  const getTotalSteps = () => totalSteps === 1 ? 0 : totalSteps;

  return (
    <div className={styles.wrapper} data-testid='ui-tutorial-tooltip' id={id}>

      <div className={styles.top}>
        <span className={styles.title}>{title}</span>
        <div onClick={onCloseClick} data-testid='tooltip-close-button'>
          <Icon name='X' size={16} className='sbw-cursor-pointer' />
        </div>
      </div>

      <div className={styles.text}>{text}</div>

      <div className={styles.bottom}>
        <div className={styles.steps}>
          {Array(getTotalSteps()).fill(1).map((_, index) => (
            <span
              className={`${styles.step} ${(index + 1) === currentStep && styles.active}`}
              key={`tutorial-tooltip-step-${index}`}
            />
          ))}
        </div>

        {!hideBackButton() && (
          <div className={styles.backButton} onClick={onBackClick} data-testid='tooltip-back-click'>
            <Icon name='ArrowLeft' size={16} color={Theme.colors.primary.green} />
          </div>
        )}

        <div className={styles.nextButton} onClick={onNextClick} data-testid='tooltip-next-click'>
          <Icon name='ArrowRight' size={16} color={Theme.colors.primary.white} />
        </div>
      </div>

      <div
        className={`${styles.triangle} ${styles[arrowPosition]} ${!arrowPositionStyles ? styles.center : ''}`}
        style={arrowPositionStyles}
      />
    </div>
  )
}

export default memo(UiTutorialTooltip);
