import { ROUTES } from "@constants"
import { AdminTableColumn } from "../entities";

export const ADMIN_PATH = ROUTES.admin;

export const ADMIN_HOME_TABS = {
  users: 'users',
  roles: 'roles',
  agencies: 'agencies',
}

export const ADMIN_TABS = {
  home: '/',
  userCreation: `${ADMIN_HOME_TABS.users}/creation`,
  agencyCreation: `${ADMIN_HOME_TABS.agencies}/creation`,
  creation: 'creation'
}

export const ADMIN_ROLES_TABLE: AdminTableColumn[] = [
  { title: "Nombre" },
  { title: "Usuarios con rol" },
  { title: "Estado", icon: 'ArrowsDownUp' },
  { title: "Acciones" },
]

export const ADMIN_USERS_TABLE: AdminTableColumn[] = [
  { title: "Nombre" },
  { title: "Correo electrónico" },
  { title: "Número de contacto" },
  { title: "Agencia", icon: 'ArrowsDownUp' },
  { title: "Anuncios creados" },
  { title: "Estado", icon: 'ArrowsDownUp' },
  { title: "Acciones" },
]

export const ADMIN_AGENCIES_TABLE: AdminTableColumn[] = [
  { title: "Nombre" },
  { title: "Correo electrónico asociado" },
  { title: 'Usuarios en agencia' },
  { title: "Estado", icon: 'ArrowsDownUp' },
  { title: "Acciones" },
]

export const USER_FORM_AGENCIES = [
  { value: '1', text: 'Chiper' },
  { value: '2', text: 'En medio' }
]

export const USER_FORM_ROLE_ID = [
  { value: '1', text: 'Súper administrador' },
  { value: '2', text: 'Administrador' },
  { value: '3', text: 'Agente' }
]

export const USER_FORM = {
  name: '',
  email: '',
  phone: '',
  areaCode: 57,
  agencyId: -1,
  roleId: -1
}

export const AGENCY_FORM = {
  name: '',
  adminName: '',
  taxNumber: '',
  adminEmail: '',
}
