import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationProgrammingTypeSelector } from '@modules/ads/store';
import { PROGRAMMING_SPECIFIC_TYPE_ID, PROGRAMMING_STANDARD_TYPE_ID } from '@modules/ads/constants';
import styles from './ads-creation-programming-options.module.sass';
import UiCard from '@ui/ui-card';

interface AdsCreationProgrammingOptionsProps { }

const AdsCreationProgrammingOptions: FC<AdsCreationProgrammingOptionsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProgrammingType = useSelector(adsCreationProgrammingTypeSelector);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t('Selecciona un tipo programación ')}
      </div>
    
      <div className={styles.cards}>
        <UiCard
          isActive={currentProgrammingType === PROGRAMMING_STANDARD_TYPE_ID}
          onClick={() => dispatch(actions.setProgrammingCurrentType(PROGRAMMING_STANDARD_TYPE_ID))}
          title={t('Programación estándar')}
          text={t('Al seleccionar la programación por este tipo, puedes seleccionar únicamente una fecha de inicio y cierre del anuncio.')}
          icon="ClockClockwise"
        />

        <UiCard
          isActive={currentProgrammingType !== PROGRAMMING_STANDARD_TYPE_ID}
          onClick={() => dispatch(actions.setProgrammingCurrentType(PROGRAMMING_SPECIFIC_TYPE_ID))}
          title={t('Programación específica')}
          text={'Al seleccionar la programación por este tipo, puedes seleccionar la fecha de inicio y cierre y asignarle una franja horaria específica.'}
          icon="Clock"
          isDisabled
        />
      </div>
    </div>
  );
}

export default AdsCreationProgrammingOptions;
