import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateField } from "@chiper-inc/sb-web-chiper";
import { useTranslation } from "react-i18next";
import { addDays, addMonths } from "date-fns";
import { actions, adsCreationProgrammingStandarSelector } from "@modules/ads/store";
import styles from "./ads-creation-programming-standard.module.sass";

interface AdsCreationProgrammingStandardProps { }

const AdsCreationProgrammingStandard: FC<AdsCreationProgrammingStandardProps> = () => {
  const { t } = useTranslation();
  const standard = useSelector(adsCreationProgrammingStandarSelector);
  const dispatch = useDispatch();

  const handleInitDateChange = useCallback((date: Date) => {
    dispatch(actions.setCreationState({
      standardProgramming: {
        ...standard,
        initDate: date.toString(),
        endDate: addMonths(date, 1).toString()
      }
    }))
  }, [dispatch, standard]);


  const handleEndDateChange = useCallback((date: Date) => {
    dispatch(actions.setCreationState({
      standardProgramming: { ...standard, endDate: date.toString() }
    }))
  }, [dispatch, standard]);


  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t("Selecciona la duración")}</div>
      <div className={styles.text}>{t("La programación debe tener una fecha de inicio y una fecha de cierre")}</div>

      <div className={styles.pickers}>
        <DateField
          onClick={handleInitDateChange}
          minDate={new Date()}
          placeholder={t("Fecha de inicio")}
          initDate={standard.initDate ? new Date(standard.initDate) : undefined}
          full
        />

        <DateField
          onClick={handleEndDateChange}
          placeholder={t("Fecha de cierre")}
          initDate={standard.endDate ? new Date(standard.endDate) : undefined}
          minDate={standard.initDate ? addDays(new Date(standard.initDate), 1) : new Date()}
          full
        />
      </div>
    </div>
  );
}

export default AdsCreationProgrammingStandard;
