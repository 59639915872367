import React, { FC } from 'react';
import AuthLogin from './pages/auth-login';

interface AuthProps { }

const Auth: FC<AuthProps> = () => {

  return (
    <AuthLogin />
  );
}

export default Auth;
