import React, { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './admin-creation-header.module.sass';
import { Icon, Theme } from '@chiper-inc/sb-web-chiper';

interface AdminCreationHeaderProps { 
  title: string;
}

const AdminCreationHeader: FC<AdminCreationHeaderProps> = ({ title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1)
  };

  return (
    <div className={styles.container}>

      <div className={styles.title}>
        <div className={styles.icon} onClick={onBackClick}>
          <Icon
            color={Theme.colors.primary.darkBlueGray}
            name='CaretLeft'
            size='24px'
            weight='light'
          />
        </div>

        {t(title)}
      </div>

      <Outlet />
    </div>
  );
}

export default AdminCreationHeader;
