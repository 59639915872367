import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Icon, Theme, UserInfo } from '@chiper-inc/sb-web-chiper';
import { useTranslation } from 'react-i18next';
import { NavLink, } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from '@config/firebase/firebase.config';
import { MENU_ITEMS, STORAGE_TOKEN } from '@constants';
import { actions, globalUserSelector } from '@config/store';
import chiperC from '@img/chiper-c-logo.png';
import chiper from '@img/chiper-logo.png';
import styles from './ui-menu.module.sass';

interface UiMenuProps { }

const UiMenu: FC<UiMenuProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector(globalUserSelector);
  const { t } = useTranslation();

  const handleLogOut = () => {
    signOut(auth);
    sessionStorage.removeItem(STORAGE_TOKEN);
    dispatch(actions.singOut())
  }

  return (
    <div className={styles.fixedwrapper} id="ui-menu">
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <img src={chiperC} alt="C" className={styles.imageC} />
          <img src={chiper} alt="C" className={styles.image} />
        </div>

        <div className={styles.center}>
          {MENU_ITEMS.map(e => (
            <NavLink
              className={(navData) => `${styles.menuItem} ${navData.isActive && styles.active}`}
              key={`menu-item-${e.icon}`}
              to={e.routeTo}
            >
              <Icon name={e.icon as keyof object} size='18px' />
              <span className={styles.name}>{t(e.name)}</span>
            </NavLink>
          ))}
        </div>

        <div className={styles.menuItem} onClick={handleLogOut} data-testid='singout'>
          <Icon name='Power' color={Theme.colors.primary.darkBlueGray} size='18px' />
          <span className={styles.name}>{t('Salir')}</span>
        </div>

        <div className={styles.user}>
          <UserInfo
            name={user.displayName}
            info={user.email}
            imgSrc={user.photoUrl}
            className={styles.userInfo}
          />

          <Avatar
            {...user.photoUrl
              ? { type: 'image', imgSrc: user.photoUrl }
              : { type: 'letter', letter: user.displayName[0] }
            }
            className={styles.avatar}
          />

        </div>
      </div>
    </div>
  );
}

export default UiMenu;
