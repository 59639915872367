import { useCallback, useState } from 'react';
import { ResponseStatus } from '@chiper-inc/sb-web-chiper/dist/Form/Uploader/interface';
import * as XLSX from 'xlsx';
import { isValidPOSSerial } from '@modules/ads/utils';
import { useDispatch } from 'react-redux';
import { actions } from '../store';

const useUploadFile = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState<number>(0);
  const [responseStatus, setResponseStatus] = useState<ResponseStatus>("idle");

  const handleWorkBook = useCallback((workBook: XLSX.WorkSheet) => {
    const firstSheet = workBook.SheetNames[0];
    const isInvalid = !workBook.Sheets[firstSheet].A1 || workBook.Sheets[firstSheet].A1.v !== 'SERIAL';

    if (isInvalid) setResponseStatus("error");
    else {
      const fileRows = XLSX.utils.sheet_to_json<{ SERIAL: string }>(workBook.Sheets[firstSheet]);

      const serials = new Set<string>();
      for (const e of fileRows) {
        if (isValidPOSSerial(String(e.SERIAL))) serials.add(e.SERIAL)
      }

      dispatch(actions.setCreationState({ serials: Array.from(serials) }));
      setResponseStatus("success");
    }
  }, [dispatch])

  const handleFile = useCallback((file: File) => {

    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    setResponseStatus("uploading");
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      const checkProgressEvent = event?.total || 0;
      const percentCompleted = Math.round((event.loaded * 100) / checkProgressEvent);
      setProgress(percentCompleted);
    }

    fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        const data = new Uint8Array(event.target.result as ArrayBufferLike);
        const workbook = XLSX.read(data, { type: 'array' });
        handleWorkBook(workbook)
      }
    }
  }, [handleWorkBook]);

  return {
    progress,
    handleFile,
    responseStatus
  }
}

export default useUploadFile;
