import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { actions, adsCreationAudiencePOSSerialsSelector } from "../store";
import { fetchGetSerials } from "@modules/ads/services";

const useGetSerials = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const serials = useSelector(adsCreationAudiencePOSSerialsSelector);

  useEffect(() => {
    if (!serials.length) {
      setIsLoading(true);
      fetchGetSerials()
        .then((data) => dispatch(actions.setPOSSerials(data)))
        .catch(() => true)
        .finally(() => setIsLoading(false))
    }
  }, [serials, dispatch]);

  return { isLoading }
}

export default useGetSerials;