import React, { FC, memo, ReactNode } from 'react';
import styles from './ui-button.module.sass';

interface UiButtonProps {
  type?: 'submit' | 'reset' | 'button';
  size?: 'small' | 'medium' | 'large';
  uiType?: 'primary' | 'secondary';
  isDisabled?: boolean;
  isBlocked?: boolean;
  onClick?: () => void;
  hasFitWidth?: boolean;
  width?: string;
  height?: string;
  children?: ReactNode;
  id?: string;
}

const UiButton: FC<UiButtonProps> = ({
  type = 'button',
  size = 'large',
  uiType = 'primary',
  isDisabled,
  isBlocked,
  onClick,
  children,
  hasFitWidth,
  width,
  height,
  id,
}) => {
  return (
    <button
      id={id}
      className={`
        ${styles.wrapper} 
        ${styles[size]} 
        ${styles[uiType]} 
        ${hasFitWidth && styles.fit} 
        ${isBlocked && styles.blocked}
      `}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      data-testid='ui-button'
      style={{ width, height }}
    >
      {children}
    </button>
  );
}

export default memo(UiButton);
