
export const SUPER_ADMIN_ROLE_ID = 1;
export const ADMIN_ROLE_ID = 2;
export const AGENT_ROLE_ID = 3;
export const VISUALIZER_ROLE_ID = 4;

export const ROLES = new Map<number, string>([
  [SUPER_ADMIN_ROLE_ID, "Super Admin"],
  [ADMIN_ROLE_ID, "Admin"],
  [AGENT_ROLE_ID, "Agente"],
  [VISUALIZER_ROLE_ID, "Visualizador"],
]);

export const USER_CREATION_PERMISSION_ID = 1;
export const USER_UPDATE_PERMISSION_ID = 2;
export const USER_DELETION_PERMISSION_ID = 3;

export const CAMPAING_CREATION_PERMISSION_ID = 4;
export const CAMPAING_UPDATE_PERMISSION_ID = 5;
export const CAMPAING_DELETION_PERMISSION_ID = 6;
export const CAMPAING_APPROVAL_PERMISSION_ID = 7;

export const ROLE_CREATION_PERMISSION_ID = 8;
export const ROLE_UPDATE_PERMISSION_ID = 9;
export const ROLE_DELETION_PERMISSION_ID = 15;

export const SEE_ALL_CAMPAINGS_PERMISSION_ID = 10;
export const UPDATE_ALL_CAMPAINGS_PERMISSION_ID = 11;

export const SEE_AGENCY_CAMPAINGS_PERMISSION_ID = 12;

export const AGENCY_CREATION_PERMISSION_ID = 13;
export const AGENCY_DELETION_PERMISSION_ID = 14;
export const AGENCY_UPDATE_PERMISSION_ID = 16;
