import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, TextField } from '@chiper-inc/sb-web-chiper';
import { isValidPOSSerial } from '@modules/ads/utils';
import { actions, adsCreationAudiencePOSSerialsSelector, adsCreationSerialsSelector } from '@modules/ads/store';
import styles from './ads-creation-audience-serial-manual.module.sass';
import UiButton from '@ui/ui-button/ui-button';

interface AdsCreationAudienceSerialManualProps { }

const AdsCreationAudienceSerialManual: FC<AdsCreationAudienceSerialManualProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState<string>("");
  const POSSerials = useSelector(adsCreationAudiencePOSSerialsSelector);
  const serials = useSelector(adsCreationSerialsSelector);

  const getSplitedSerials = useCallback(() => (
    value.trim().split(/[\s," "-.]+/)
  ), [value]);

  const isInvalidField = () => {
    const userSerials = getSplitedSerials();
    for (const s of userSerials) {
      if (!isValidPOSSerial(s)) return true;
    }
    return false;
  }

  const onSubmitValue = useCallback((e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(actions.setCreationState({
      serials: Array.from(new Set([...serials, ...getSplitedSerials()]))
    }))
    setValue("")
  }, [dispatch, getSplitedSerials, serials]);

  const onRemoveClick = useCallback((serialSelected: string) => {
    dispatch(actions.setCreationState({ serials: serials.filter(i => i !== serialSelected) }))
  }, [dispatch, serials])

  const hasError = !!value && isInvalidField();

  return (
    <div className={styles.wrapper}>
      <form className={styles.form} onSubmit={onSubmitValue}>
        <TextField
          onChange={(e) => setValue(e.target.value)}
          isValid={!hasError}
          value={value}
          helperText={hasError ? t("No es un formato de serial") : ""}
          placeholder={t("Escriba uno o más seriales")}
          name="serial"
          full
          autoComplete="off"
          data-testid="chiper-text-field"
        />

        <UiButton
          type='submit'
          height='50px'
          hasFitWidth
          isDisabled={isInvalidField()}
          uiType='primary'
        >
          {t('Cargar')}
        </UiButton>
      </form>

      <div className={styles.serialsCard}>
        {serials.map((item) => (
          <div
            className={`${styles.card} ${!POSSerials.includes(item) ? styles.error : ""}`}
            key={`serial-${item}`}
          >
            <span className={styles.text}>{item}</span>
            <div className={styles.icon} onClick={() => onRemoveClick(item)}>
              <Icon name="X" size="12px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdsCreationAudienceSerialManual;
