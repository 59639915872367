import { AdminAgencies, AdminRoles, AdminUsers } from "../store";
import { AgencyForm, UserForm } from "../entities";
import axios from "axios";

export const fetchGetRoles = (): Promise<AdminRoles[]> =>
  axios.get('/role/all')
    .then((resp) => resp.data);

export const fetchGetUsers = (): Promise<AdminUsers[]> =>
  axios.get('/user/all')
    .then((resp) => resp.data);

export const fetchCreateUser = (body: UserForm): Promise<UserForm> =>
  axios.post('/user', body)
    .then((resp) => resp.data);

export const fetchGetAgencies = (): Promise<AdminAgencies[]> =>
  axios.get('/agency/all')
    .then((resp) => resp.data);

export const fetchCreateAgency = (body: AgencyForm): Promise<AgencyForm> =>
  axios.post('/agency', body)
    .then((resp) => resp.data);