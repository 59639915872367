import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@config/store';
import { Ad } from '../entities';

export const homeDashboardAdsSelector = createSelector(
  (state: RootState) => state.home.data.ads,
  (state: Ad[]) => state
);

export const homeDashboardSearchValue = createSelector(
  (state: RootState) => state.home.data.searchValue,
  (state: string) => state
);