import React, { FC } from "react";
import styles from "./admin-roles.module.sass";
import AdminTop from "@modules/admin/components/admin-top";
import AdminRolesTable from "@modules/admin/components/admin-roles-table";
import usePermissions from "@hooks/use-permissions";

interface AdminRolesProps { }

const AdminRoles: FC<AdminRolesProps> = () => {
  const { canCreateRoles } = usePermissions();
  const activeButton = false;

  return (
    <div className={styles.wrapper}>

      <AdminTop
        placeholder="Busca por nombre de rol"
        buttonText="Crear rol"
        hasDisabledButton={!activeButton || !canCreateRoles()}
      />

      <AdminRolesTable />

    </div>
  );
}

export default AdminRoles;
