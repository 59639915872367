import { GlobalUserState } from "@config/store";
import { ROUTES } from "./routes.constants";

export const MIN_WIDTH_DESKTOP = 768;
export const STORAGE_TOKEN = "CHIPER_POS_ADS";
export const INVALID_TOKEN_STATUS = 403;

export const INTIAL_USER_STATE: GlobalUserState = {
  isLogged: false,
  displayName: "",
  email: "",
  photoUrl: "",
  permissionsId: [],
  roleId: 0,
  agencyId: 0,
  userId: 0
}

export const MENU_ITEMS = [
  { icon: "Megaphone", routeTo: ROUTES.home, name: "Tablero de anuncios" },
  { icon: "UsersThree", routeTo: ROUTES.admin, name: "Perfiles y roles" },
];
