// ------------------ Servicios del home ----------------------

export const getAdStates = [
  { id: 1, key: 'Programado', color: 'secondary' },
  { id: 2, key: 'Finalizado', color: 'success' },
  { id: 3, key: 'Cancelado', color: 'error' },
  { id: 4, key: 'En curso', color: 'warning' },
]

export const getAdminStates = [
  { id: 1, key: 'Activo', color: 'success' },
  { id: 2, key: 'Pendiente', color: 'warning' },
]

export const getAdAudiences = [
  { id: 1, key: 'Por ciudad' },
  { id: 2, key: 'Serial' },
]

export const getAds = [{
  id: 123,
  cpg: "Coca",
  name: "Promo Coca",
  skus: [
    { sku: '', name: "Coca-Cola x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Coca-Cola Light x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" }
  ],
  audience: {
    id: 1,
    //el diseño marca que se podra ver las ciudades o los seriales
    values: [
      { name: 'Bogota' },
      { name: 'Ciudad de Mexico' },
    ]
  },
  scope: 320,
  initDate: "2023/03/28",
  endDate: "2023/04/15",
  stateId: 2
},
{
  id: 163,
  cpg: "PepsiCo",
  name: "Promo Pepsi",
  skus: [
    { sku: '', name: "Pepsi x 2.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi x 0.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi Light x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi x 2.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi x 0.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
    { sku: '', name: "Pepsi Light x 1.5L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" }
  ],
  audience: {
    id: 1,
    //el diseño marca que se podra ver las ciudades o los seriales
    values: [
      { name: 'Cali' },
      { name: 'Medellín' },
      { name: 'Barranquilla' },
    ]
  },
  scope: 50,
  initDate: "2023/04/15",
  endDate: "2023/04/30",
  stateId: 1
},
{
  id: 1874,
  cpg: "Empanadas Bartolo",
  name: "Promo Bartolo 2",
  skus: [
    { sku: '', name: "Empanada de Choclo", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
  ],
  audience: {
    id: 2,
    //el diseño marca que se podra ver las ciudades o los seriales
    values: [
      { name: 'DA0419CF40320' },
      { name: 'DA0419CF40322' },
      { name: 'DA0419CF40355' },
      { name: 'DA0419CF403A0' },
      { name: 'DA0419CF40397' },
    ]
  },
  scope: 500,
  initDate: "2023/03/15",
  endDate: "2023/04/15",
  stateId: 4
}]


// ------------------ Servicios de busqueda (Definicion) ----------------------

export const getCPGs = [
  { id: 132, name: "Coca", imageUrl: "https://img-api.yumapos.co.uk/image/crop/256x256/c586cdb5-ac7f-a021-9081-a8ab3d654f1b.jpg" },
  { id: 99, name: "Pepsi", imageUrl: "https://cdn.iconscout.com/icon/free/png-256/pepsi-226305.png" },
  { id: 133, name: "Coca 2", imageUrl: "https://cdn.iconscout.com/icon/free/png-256/coca-2-226146.png" },
  { id: 990, name: "Coco ", imageUrl: "https://cdn-icons-png.flaticon.com/128/3239/3239295.png" },
  { id: 1232, name: "Mate", imageUrl: "https://styles.redditmedia.com/t5_2ro1q/styles/communityIcon_y9fy4u0905331.png" },
  { id: 1132, name: "Mate2", imageUrl: "https://styles.redditmedia.com/t5_2ro1q/styles/communityIcon_y9fy4u0905331.png" },
]

export const getSKusByCPG = [
  { id: 5, name: "Coca x 1.5L", imageUrl: "https://images.vexels.com/media/users/3/182730/isolated/lists/cf916aa317b2d2c954c299a9d1f7e4f7-botella-icon-canada.png" },
  { id: 6, name: "Coca x 2.25L", imageUrl: "https://images.vexels.com/media/users/3/182730/isolated/lists/cf916aa317b2d2c954c299a9d1f7e4f7-botella-icon-canada.png" },
  { id: 95, name: "Coca x 10.25L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5827/5827662.png" },
  { id: 9, name: "Coca x 5.5L", imageUrl: "https://i.pinimg.com/originals/f8/31/7f/f8317f8a071a1162c2c47c4aee3aecec.png" },
  { id: 94, name: "mate x 10.25L", imageUrl: "https://cdn-icons-png.flaticon.com/128/5346/5346913.png" },
  { id: 900, name: "mate x 1.25L", imageUrl: "https://cdn.iconscout.com/icon/premium/png-256-thumb/mate-3966098-3326999.png?f=webp&w=256" },
]

// ------------------ Servicios de creacion (audiencia) ----------------------

export const getCitiesWithPOS = [
  { id: 1, name: 'Barranquilla' },
  { id: 2, name: 'Bogota' },
  { id: 3, name: 'Medellin' },
  { id: 4, name: 'Cali' },
  { id: 5, name: 'Ciudad de Mexico' },
]
// pense que podriamos tener los seriales en el front y validar cuales son correctos en la carga 
// para evitar estar consultando cuantos son validos y darle mas fluidez a los flujos
export const getSeriales = [
  "DA0419CF40320",
  "DA0419CF40320",
  "DA0419CF40320",
  "DA0419CF40320",
  "DA0419CF40320",
  "DA0419CF40320",
]

// ------------------ Servicios de creacion ----------------------

export const body = {
  cpgId: 12354,
  skus: [{ id: 1 }, { id: 78 }, { id: 199 }], // o skusIds
  skusIds: [1, 78, 199], // o skus
  details: {
    name: 'Promo Cocucha',
    description: 'Promo de verano para tomar coca',
  },
  audience: {
    id: 1,
    // se enviaria las ciudades O los seriales, segun lo elegido
    cities: [{ id: 1 }, { id: 5 }], // o citiesIds
    citiesIds: [1, 5], // o cities
    serials: [
      "DA0419CF40320",
      "DA0419CF40323",
    ]
  },
  programming: {
    id: 1,
    standard: {
      initDate: '28/03/23',
      endDate: '28/03/23',
    },
    specific: {}
  },
  videoUrl: 'https://youtub.com/whahsd=8198233',
  advertisingIncome: '1000',
}

// ------------------- dias y semanas para busca especifica --------------

export const getFrequency = [
  { id: 1, name: "L" },
  { id: 2, name: "M" },
  { id: 3, name: "M" },
  { id: 4, name: "J" },
  { id: 5, name: "V" },
  { id: 6, name: "S" },
  { id: 7, name: "D" },
]

export const getWeeks = [
  { value: "1", text: "No se repite" },
  { value: "2", text: "Se repite cada semana" },
  { value: "3", text: "Se repite cada 2 semanas" },
  { value: "4", text: "Se repite cada 3 semanas" },
]
