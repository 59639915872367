import React, { FC } from 'react';
import HomeRouter from './routers/home.router';

interface HomeProps { }

const Home: FC<HomeProps> = () => {

  return (
    <HomeRouter />
  );
}

export default Home;
