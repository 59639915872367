import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useSubmitAd from "@modules/ads/hooks/use-submit-ad";
import useClevertapEvent from "@modules/ads/hooks/use-clevertap-event";
import { CREATION_NEXT_BUTTON_ROUTES, CREATION_RETURN_BUTTON_ROUTES } from "@modules/ads/constants";
import { adsCreationStepSelector, adsUiCreationStepCompleteSelector } from "@modules/ads/store";
import styles from "./ads-creation-buttons.module.sass";
import { ChiperButton } from "@chiper-inc/sb-web-chiper";

interface AdsCreationButtonsProps { }

const AdsCreationButtons: FC<AdsCreationButtonsProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createNewAd } = useSubmitAd();
  const { setEvent } = useClevertapEvent();
  const step = useSelector(adsCreationStepSelector);
  const isCompleteStep = useSelector(adsUiCreationStepCompleteSelector);

  const onNextClick = () => {
    const route = CREATION_NEXT_BUTTON_ROUTES.get(step);
    if (route) {
      navigate(route);
      setEvent();
    }
    else createNewAd()
  }

  const onBackClick = () => {
    navigate(CREATION_RETURN_BUTTON_ROUTES.get(step) ?? "")
  }

  return (
    <div className={styles.wrapper}>
      {step !== 1 && (
        <ChiperButton
          uiType="outlined"
          className={styles.button}
          text={t("Volver")}
          onClick={onBackClick}
          size="large"
          data-testid="chiper-button-back"
        />
      )}

      <ChiperButton
        className={styles.button}
        text={t("Continuar")}
        disabled={!isCompleteStep}
        onClick={onNextClick}
        size="large"
        data-testid="chiper-button-next"
      />
    </div>
  );
}

export default AdsCreationButtons;
