import axios from "axios";
import { AdAudience, AdState, OnboardingBody, OnboardingItem } from "@entities";
import { sleep } from "@utils";
import { getAdAudiences, getAdStates, getAdminStates } from "../a";

export const fetchGetAdStates = (): Promise<AdState[]> =>
  sleep(1)
    .then(() => getAdStates)

export const fetchGetAdAdminStates = (): Promise<AdState[]> =>
  sleep(1)
    .then(() => getAdminStates);

export const fetchGetAdAudiences = (): Promise<AdAudience[]> =>
  sleep(1)
    .then(() => getAdAudiences)

export const fetchGetOnboardings = (userId: number): Promise<OnboardingItem[]> =>
  axios.get(`onboarding/user/${userId}`)
    .then((resp) => resp.data);

export const fetchSetOnboarding = (body: OnboardingBody): Promise<boolean> =>
  axios.post("onboarding/user", body)
    .then(() => true)
    .catch(() => false);
