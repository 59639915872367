import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ads-creation-definition-search-bar.module.sass';
import UiSearchBar from '@ui/ui-search-bar';
import useSearchCGPs from '@modules/ads/hooks/use-search-cgps';

interface AdsCreationDefinitionSearchBarProps { }

const AdsCreationDefinitionSearchBar: FC<AdsCreationDefinitionSearchBarProps> = () => {
  const { t } = useTranslation();
  const data = useSearchCGPs();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t('Selecciona un CPG')}
      </div>

      <UiSearchBar
        defaultValue={data.currentCPG}
        onChange={data.searchCGP}
        hasAutoFocus
        results={data.results}
        onResultClick={data.selectCPG}
        hasDebounce
      />
    </div>
  );
}

export default AdsCreationDefinitionSearchBar;
