import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  actions,
  adsCreationEspecificProgrammingSelector,
  adsCreationSelector,
} from "@modules/ads/store";
import { addDays } from "date-fns";
import useGetEspecificInfo from "@modules/ads/hooks/use-get-especific-info";
import { ResponseItem } from "@entities";
import styles from "./ads-creation-programming-especific.module.sass";
import { SelectField, DateField, TextField } from "@chiper-inc/sb-web-chiper";

interface AdsCreationProgrammingEspecificProps {}

const AdsCreationProgrammingEspecific: FC<AdsCreationProgrammingEspecificProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading } = useGetEspecificInfo();
  const data = useSelector(adsCreationSelector);
  const especific = useSelector(adsCreationEspecificProgrammingSelector);

  useEffect(() => {
    dispatch(actions.setIsCurrentStepCompleted(
      !!especific.initDate 
      && !!especific.endDate 
      && !!especific.initTime 
      && !!especific.endTime 
      && !!especific.weekRepeat 
      && !!especific.dayRepeat
    ))
  }, [dispatch, especific]);

  const selectFields = useMemo(() =>
    Array.from({ length: 24 }, (v, i) => {
      let text;
      if (i === 12) {
        text = "12:00 p.m";
      } else {
        text = i >= 12 ? `${i - 12}:00 p.m` : `${i}:00 a.m`;
      }
      return {
        text,
        value: String(i),
      };
    }),
  []);

  const handleDateChange = useCallback(
    (date: Date, key: "initDate" | "endDate") => {
      let finalDate;
      if (key === 'initDate') {
        finalDate = addDays(new Date(date), 30)
      } else finalDate = date;
      dispatch(
        actions.setCreationState({
          especificProgramming: { ...especific, 
            initDate: key === 'initDate' ? String(date) : (especific.initDate ? especific.initDate : ''),
            endDate: String(finalDate)
          },
        })
      );
    },
    [dispatch, especific]
  );

  const handleTimeChange = useCallback(
    (value: string) => {
      let endTime = Number(value);
      endTime++;
      let getEndTime;
      if (endTime === 12) {
        getEndTime = "12:00 p.m";
      } else {
        getEndTime =
          endTime >= 12 ? `${endTime - 12}:00 p.m` : `${endTime}:00 a.m`;
      }
      dispatch(
        actions.setCreationState({
          especificProgramming: {
            ...especific,
            initTime: value,
            endTime: getEndTime,
          },
        })
      );
    },
    [dispatch, especific]
  );

  const handleWeekChange = useCallback(
    (week: string) => {
      dispatch(
        actions.setCreationState({
          especificProgramming: { ...especific, weekRepeat: week },
        })
      );
    },
    [dispatch, especific]
  );

  const handleDayClick = useCallback((day: ResponseItem) => {
    dispatch(actions.setCreationState({
      especificProgramming: {
        ...especific,
        dayRepeat: especific.dayRepeat.some(item => item.id === day.id)
        ? especific.dayRepeat.filter(item => item.id !== day.id)
        : [...especific.dayRepeat, day]
      }
    }))
  }, [dispatch, especific]);

  const handleFirstStep = useMemo(() => {
    if (!!especific.initDate && !!especific.endDate && !!especific.initTime && !!especific.endTime) {
      return false
    } return true;
  }, [especific]);

  if (isLoading) return <> </>
  
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t("Selecciona la duración")}</div>

      <div className={styles.dateRange}>
        <DateField
          name="init"
          onClick={(d) => handleDateChange(d, "initDate")}
          initDate={!!especific.initDate ? new Date(especific.initDate) : undefined}
          formatToShow="MMM dd, yyyy"
          placeholder={t("Fecha de inicio")}
          minDate={new Date()}
        />
        <DateField
          name="end"
          onClick={(d) => handleDateChange(d, "endDate")}
          initDate={!!especific.initDate ? new Date(especific.endDate) : undefined}
          formatToShow="MMM dd, yyyy"
          placeholder={t("Fecha de cierre")}
          minDate={!!especific.initDate ? addDays(new Date(especific.initDate), 1) : new Date()}
        />
      </div>

      <div className={styles.time}>
        <SelectField
          icon="ClockAfternoon"
          name="init"
          key={`time-select-${selectFields.map(item => item.text)}`}
          onChange={handleTimeChange}
          placeholder={t("Franja horario de inicio")}
          value={!!especific.initTime ? especific.initTime : ""}
          options={selectFields}
        />
        <TextField
          icon="ClockAfternoon"
          name="end"
          placeholder={t("Franja horario de cierre")}
          value={!!especific.endTime ? especific.endTime : ""}
          onChange={() => true}
          readOnly
        />
      </div>

      <div className={styles.week}>
        <div className={styles.left}>
          <div className={styles.text}>{t("Se repite:")}</div>
          <SelectField
            name="weeks"
            key={`week-day-${data.weeks.map(item => item.text)}`}
            value={!!especific.weekRepeat ? especific.weekRepeat : "1"}
            onChange={handleWeekChange}
            options={data.weeks}
            disabled={handleFirstStep}
            placeholder=""
          />
        </div>

        <div className={styles.right}>
          <div className={styles.text}>{t("Los dias:")}</div>
          <div className={`${styles.frequency} ${handleFirstStep && styles.disabled}`}>
            {data.frequency.map((item, index) => (
              <button
                className={`
                  ${styles.day}
                  ${handleFirstStep && styles.disabled}
                  ${especific.dayRepeat.some(day => day.id === item.id) && !handleFirstStep && styles.selected}
                `}
                key={`frequency-day-${index}`}
                disabled={handleFirstStep}
                onClick={() => handleDayClick(item)}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsCreationProgrammingEspecific;
