import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { GoogleAuthProvider, deleteUser, signInWithPopup, signOut } from "firebase/auth";
import { auth } from '@config/firebase/firebase.config';
import { actions } from '@config/store';
import { STORAGE_TOKEN } from '@constants';
import { showNotification } from '@utils';
import { fetchLogin } from '@modules/auth/services';
import Clevertap from '@config/clevertap';

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: 'select_account',
});

const useGoogleLogin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem(STORAGE_TOKEN) || '{}');
    if (!!storage) {
      dispatch(actions.setUser(storage));
      Clevertap.identifyUser(storage.displayName, storage.userId, storage.email);
    }
  }, [dispatch]);

  const validateUser = () => {
    const user = auth.currentUser;
    const userInfo = {
      displayName: user?.displayName || '',
      email: user?.email || '',
      isLogged: true,
      photoUrl: user?.photoURL || ''
    }
    dispatch(actions.setIsLoading(true));
    fetchLogin(user?.uid || '', user?.email || '')
      .then(info => {
        Clevertap.identifyUser(userInfo.displayName, info.userId, userInfo.email);
        sessionStorage.setItem(STORAGE_TOKEN, JSON.stringify({
          ...userInfo,
          ...info,
        }));
        dispatch(actions.setUser({
          ...userInfo,
          permissionsId: info.permissionsId,
          roleId: info.roleId,
          agencyId: info.agencyId,
          userId: info.userId
        }));
      })
      .catch(() => {
        signOut(auth);
        if (auth.currentUser) deleteUser(auth.currentUser);
        showNotification(`La dirección ${user?.email} no pertenece a un usuario registrado`, 'alert');
      })
      .finally(() => dispatch(actions.setIsLoading(false)));
  }

  const login = () => {
    signInWithPopup(auth, provider)
      .then(() => validateUser())
      .catch(() => showNotification('No logramos acceder mediante su cuenta de Google', 'alert'));
  }

  return {
    login
  }
}

export default useGoogleLogin;
