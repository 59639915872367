import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Uploader } from '@chiper-inc/sb-web-chiper';
import useUploadFile from '@modules/ads/hooks/use-upload-file';
import styles from './ads-creation-audience-serial-upload.module.sass';

interface AdsCreationAudienceSerialUploadProps { }

const AdsCreationAudienceSerialUpload: FC<AdsCreationAudienceSerialUploadProps> = () => {
  const { t } = useTranslation();
  const { handleFile, progress, responseStatus } = useUploadFile();

  return (
    <div className={styles.wrapper}>
      <Uploader
        title={t('Arrastra y suelta o busca tus archivos')}
        description={t('Solo puedes cargar archivos en formato XLS')}
        limitErrorMessage=''
        typesErrorMessage={t('Error al cargar el documento, revise el formato e intente nuevamente')}
        accept='.xls, .xlsx'
        progress={progress}
        progressMessage={progress ? `${progress}%` : ""}
        responseStatus={responseStatus}
        responseErrorMessage={responseStatus === "error" ? t('Error al cargar el documento, revise el formato e intente nuevamente') : ""}
        iconName='MicrosoftExcelLogo'
        onHandleChange={handleFile}
        loadingMessage='Cargando...'
      />
    </div>
  );
}

export default AdsCreationAudienceSerialUpload;
