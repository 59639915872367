import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "@modules/home/store";
import { globalAdStatesSelector } from "@config/store";
import { homeDashboardAdsSelector } from "../store";
import { sortAscNumberFn, sortAscStringFn, sortDscNumberFn, sortDscStringFn } from "@utils";

const IS_ASC_ORDER = true;

const useSortTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const adStates = useSelector(globalAdStatesSelector);
  const ads = useSelector(homeDashboardAdsSelector);
  const [activeColumn, setActiveColumn] = useState<number>(0);
  const order = useRef<any>({
    0: !IS_ASC_ORDER,
    1: IS_ASC_ORDER,
    2: IS_ASC_ORDER,
    4: IS_ASC_ORDER,
    6: IS_ASC_ORDER,
    7: IS_ASC_ORDER
  })

  const sortById = () => {
    const sortFn = order.current[0] === IS_ASC_ORDER ? sortAscNumberFn : sortDscNumberFn;
    let array = [...ads].sort((a, b) => sortFn(a.id, b.id));
    dispatch(actions.setAds(array));
  }

  const sortByCPG = () => {
    const sortFn = order.current[1] === IS_ASC_ORDER ? sortAscStringFn : sortDscStringFn;
    let array = [...ads].sort((a, b) => sortFn(a.cpg, b.cpg));
    dispatch(actions.setAds(array));
  }

  const sortByName = () => {
    const sortFn = order.current[2] === IS_ASC_ORDER ? sortAscStringFn : sortDscStringFn;
    let array = [...ads].sort((a, b) => sortFn(a.name, b.name));
    dispatch(actions.setAds(array));
  }

  const sortByAudience = () => {
    const sortFn = order.current[4] === IS_ASC_ORDER ? sortAscNumberFn : sortDscNumberFn;
    let array = [...ads].sort((a, b) => sortFn(a.audience.id, b.audience.id));
    dispatch(actions.setAds(array));
  }

  const sortByDate = () => {
    const sortFn = order.current[6] === IS_ASC_ORDER ? sortAscNumberFn : sortDscNumberFn;
    let array = [...ads].sort((a, b) => sortFn(new Date(a.initDate).getTime(), new Date(b.initDate).getTime()));
    dispatch(actions.setAds(array));
  }

  const sortByState = () => {
    const sortFn = order.current[7] === IS_ASC_ORDER ? sortAscStringFn : sortDscStringFn;
    let array = [...ads].sort((a, b) => sortFn(
      t(adStates.find(e => e.id === a.stateId)!.key),
      t(adStates.find(e => e.id === b.stateId)!.key)
    ));
    dispatch(actions.setAds(array));
  }

  const sortObjet = new Map([
    [0, sortById],
    [1, sortByCPG],
    [2, sortByName],
    [4, sortByAudience],
    [6, sortByDate],
    [7, sortByState],
  ]);

  const sortBy = (type: number) => {
    if (sortObjet.has(type)) {
      sortObjet.get(type)!();
      setActiveColumn(type);
      order.current[type] = !order.current[type];
    }
  }

  return {
    sortBy,
    activeColumn
  }
}

export default useSortTable;
