import { ROUTES } from "@constants"

export const ADS_PATH = ROUTES.ads;

export const ADS_TABS = {
  creation: 'creation',
  update: 'update'
}

export const ADS_CREATION_TABS = {
  definition: 'definition',
  audience: 'audience',
  programming: 'programming',
  format: 'format',
  verification: 'verification',
}
