import React, { FC, memo } from "react";
import styles from "./ui-empty-state.module.sass";

interface UiEmptyStateProps {
  img: string;
  title: string;
  text: string;
  className?: string;
}

const UiEmptyState: FC<UiEmptyStateProps> = ({ img, title, text, className }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <img src={img} alt="ilustration" className={styles.image} />

      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        <p className={styles.text}>{text}</p>
      </div>
    </div>
  );
}

export default memo(UiEmptyState);
