import React, { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { actions, adsCreationDetailSelector } from "@modules/ads/store";
import UiTextAreaField from "@ui/ui-text-area-field";
import UiInputField from "@ui/ui-input-field";
import styles from "./ads-creation-definition-details.module.sass";

interface AdsCreationDefinitionDetailsProps {}

const AdsCreationDefinitionDetails: FC< AdsCreationDefinitionDetailsProps> = () => {
  const dispatch = useDispatch();
  const detail = useSelector(adsCreationDetailSelector);
  const [keywords, setKeywords] = useState<string[]>(detail.keywords);
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(actions.setCreationState({ detail: { ...detail, [e.target.name]: e.target.value } }));
  };

  const handleKeywords = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputKeywords = e.target.value.split(",").map((keyword) => keyword.trim());
    const limitedKeywords = inputKeywords.slice(0, 5);

    setKeywords(limitedKeywords);
    dispatch(actions.setCreationState({ detail: { ...detail, keywords: limitedKeywords }})
  )};

  const maxKeywords = 5;
  const wordCount = keywords.join(' ').split(/\s+/).filter(Boolean).length;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t("Detalles del anuncio")}
      </div>

      <UiInputField
        label={t("Nombre del anuncio")}
        name="name"
        placeholder={t("Nombre del anuncio")}
        value={detail.name}
        onChange={handleChange}
        maxCharacters={35}
      />

      <UiTextAreaField
        label={t("Descripción del anuncio")}
        name="description"
        placeholder={t("Descripción del anuncio")}
        value={detail.description}
        onChange={handleChange}
        maxCharacters={120}
        height="186px"
      />

      <div>
        <UiTextAreaField
          label={t("Palabras-clave del anuncio")}
          name="palabras-clave"
          placeholder={t("Escribe o pega 5 palabras-clave asociadas a este anuncio separadas por comas")}
          value={keywords.join(", ")}
          onChange={handleKeywords}
        />
        <div className={styles.wordCount}>
          {wordCount}/{maxKeywords}
        </div>
      </div>

    </div>
  );
};

export default AdsCreationDefinitionDetails;
