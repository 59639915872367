import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADS_CREATION_Audience_STEP, AUDIENCE_BY_CITIES_TYPE_ID } from '@modules/ads/constants';
import { actions, adsCreationAudienceTypeSelector } from '@modules/ads/store';
import AdsCreationAudienceBySerial from '@modules/ads/components/ads-creation-audience-by-serial';
import AdsCreationAudienceOptions from '@modules/ads/components/ads-creation-audience-options';
import styles from './ads-creation-audience.module.sass';
import AdsCreationAudienceCities from '@modules/ads/components/ads-creation-audience-cities';

interface AdsCreationAudienceProps { }

const AdsCreationAudience: FC<AdsCreationAudienceProps> = () => {
  const dispatch = useDispatch();
  const currentType = useSelector(adsCreationAudienceTypeSelector);

  useEffect(() => {
    dispatch(actions.setCurrentCreationStep(ADS_CREATION_Audience_STEP))
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <AdsCreationAudienceOptions />

      {currentType === AUDIENCE_BY_CITIES_TYPE_ID
        ? <AdsCreationAudienceCities />
        : <AdsCreationAudienceBySerial />
      }
    </div>
  );
}

export default AdsCreationAudience;
