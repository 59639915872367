import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationAudienceTypeSelector } from '@modules/ads/store';
import { AUDIENCE_BY_CITIES_TYPE_ID, AUDIENCE_BY_FILE_SERIALS_TYPE_ID } from '@modules/ads/constants';
import styles from './ads-creation-audience-options.module.sass';
import UiCard from '@ui/ui-card';

interface AdsCreationAudienceOptionsProps { }

const AdsCreationAudienceOptions: FC<AdsCreationAudienceOptionsProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentType = useSelector(adsCreationAudienceTypeSelector);

  const onByCitiesClick = useCallback(() => {
    dispatch(actions.setAudienceCurrentType(AUDIENCE_BY_CITIES_TYPE_ID));
    dispatch(actions.setCreationState({ serials: [] }))
  }, [dispatch]);

  const onBySerialClick = useCallback(() => {
    dispatch(actions.setAudienceCurrentType(AUDIENCE_BY_FILE_SERIALS_TYPE_ID));
    dispatch(actions.setCreationState({ citiesIds: [] }))
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {t('Selecciona un tipo de audiencia')}
      </div>

      <div className={styles.cards}>
        <UiCard
          isActive={currentType === AUDIENCE_BY_CITIES_TYPE_ID}
          onClick={onByCitiesClick}
          title={t('Por ciudad')}
          text={t('Al seleccionar la audiencia por este tipo, puedes segmentar directamente el target total por ciudad.')}
          icon="MapPinLine"
        />

        <UiCard
          isActive={currentType !== AUDIENCE_BY_CITIES_TYPE_ID}
          onClick={onBySerialClick}
          title={t('Por serial de POS')}
          text={'Al seleccionar la audiencia por este tipo, puedes segmentar directamente dispositivos específicos.'}
          icon="ListNumbers"
        />
      </div>
    </div>
  );
}

export default AdsCreationAudienceOptions;
