import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdsRouter from "./routers/ads.router";
import usePermissions from "@hooks/use-permissions";
import { ROUTES } from "@constants";

interface AdsProps { }

const Ads: FC<AdsProps> = () => {
  const { canCreateCampaings } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (!canCreateCampaings()) navigate(ROUTES.home);
  }, [canCreateCampaings, navigate]);

  return (
    <AdsRouter />
  );
}

export default Ads;
