import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Frequency, SelectField, TextField } from '@chiper-inc/sb-web-chiper';
import { adsCreationSelector } from '@modules/ads/store';
import useGetEspecificInfos from '@modules/ads/hooks/use-get-especific-info';
import styles from './ads-creation-verification-duration.module.sass';
import UiInputDate from '@ui/ui-input-date';
import UiSwitch from '@ui/ui-switch';

interface AdsCreationVerificationDurationProps { }

const AdsCreationVerificationDuration: FC<AdsCreationVerificationDurationProps> = () => {
  const { t } = useTranslation();
  const { isLoading } = useGetEspecificInfos();
  const data = useSelector(adsCreationSelector);

  const handleInitTimeText = () => {
    const initTime = Number(data.especificProgramming.initTime);
    if (initTime === 12) return "12:00 p.m";
    return initTime >= 12 ? `${initTime - 12}:00 p.m` : `${initTime}:00 a.m`;
  };

  if (isLoading) return <> </>

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('Selecciona la duración')}</div>
      <div className={styles.dateFields}>
        <UiInputDate
          onChange={() => true}
          label={t('Fecha de inicio')}
          value={
            !!data.standardProgramming.initDate
              ? new Date(data.standardProgramming.initDate)
              : new Date(data.especificProgramming.initDate)
          }
          isReadOnly
        />
        <UiInputDate
          onChange={() => true}
          label={t('Fecha de cierre')}
          value={
            !!data.standardProgramming.endDate
              ? new Date(data.standardProgramming.endDate)
              : new Date(data.especificProgramming.endDate)
          }
          isReadOnly
        />

        <TextField
          placeholder='Franja horaria'
          icon='ClockAfternoon'
          name='time'
          value={
            !!data.especificProgramming.initTime
              ? `${handleInitTimeText()} - ${data.especificProgramming.endTime}`
              : ""
          }
          onChange={() => true}
          readOnly
          full
        />
      </div>

      <div className={styles.switch}>
        <div className={styles.switchTitle}>{t('Programación personalizada')}</div>
        <UiSwitch isActive={false} onChange={() => true} />
      </div>

      <div className={styles.weekDays}>
        <div className={styles.dayTitle}>{t('Aparece únicamente:')}</div>
        <div className={styles.daysList}>
          {data.frequency.map((item, index) => (
            <Frequency
              key={`frequency-${index}-${item}`}
              text={item.name}
              onClick={() => true}
              selected={
                !!data.especificProgramming.dayRepeat
                  ? data.especificProgramming.dayRepeat.some(day => day.id === item.id)
                  : false
              }
              disabled
            />
          ))}
        </div>
      </div>

      <div className={styles.week}>
        <div className={styles.weekTitle}>{t('Se repite:')}</div>
        <SelectField
          name="weeks"
          key={`week-day-${data.weeks.map(item => item.text)}`}
          value={
            !!data.especificProgramming.weekRepeat
              ? data.especificProgramming.weekRepeat
              : ""
          }
          onChange={() => true}
          options={data.weeks}
          disabled
          placeholder=""
          full
        />
      </div>

    </div>
  )
}

export default AdsCreationVerificationDuration;