export const currency = {
  colombia: {
    "sign": "$",
    "decimals": "0",
    "thousand_separator": ".",
    "decimal_separator": ",",
  },
  mexico: {
    "sign": "$",
    "decimals": "2",
    "thousand_separator": ",",
    "decimal_separator": ".",
  },
  brazil: {
    "sign": "R$",
    "decimals": "2",
    "thousand_separator": ".",
    "decimal_separator": ",",
  }
};
