import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { errors } from "./errors.translations";
import { currency } from "./currency.translations";
import { country } from "./country.translations";

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export default i18n
  .use(initReactI18next)
  .init({
    resources: {
      co: {
        translation: {
          "country": { ...country.colombia },
          "currency": { ...currency.colombia },
          "errors": { ...errors.spanish },
        }
      },
      mx: {
        translation: {
          "country": { ...country.mexico },
          "currency": { ...currency.mexico },
          "errors": { ...errors.spanish },
        }
      },
      br: {
        translation: {
          "country": { ...country.brazil },
          "currency": { ...currency.brazil },
          "errors": { ...errors.portuguese },
        },
      }
    },
    lng: "co",
    fallbackLng: "co",
    debug: false,
    returnNull: false,
    interpolation: {
      escapeValue: false
    }
  });
