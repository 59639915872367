export const DASH_BUTTON_ID = "home-dash-button-id";
export const DASH_SEARCHBAR_ID = "home-dash-searchbar-id";
export const DASH_TITLE_ID = "home-dash-title-id";
export const DASH_TABLE_ID = "home-dash-table-id";
export const DASH_TOOLTIP_ID = "home-dash-tooltip-id";

export const OBOARDING_STEPS = [
  {
    title: "Este es el menú principal",
    text: "Aquí podrás acceder a las secciones de la plataforma de acuerdo al rol que tengas. Desde acá también podrás cerrar sesión.",
    itemBoxProps: { itemId: "ui-menu", paddingX: 0, paddingY: 0 },
    tooltip: { arrowPosition: "left", position: "menu" },
  },
  {
    title: "Sección de anuncios",
    text: "Aquí podrás ver y gestionar los anuncios pautados por las marcas.",
    itemBoxProps: { itemId: DASH_TITLE_ID },
    tooltip: { arrowPosition: "top", position: "title" },
  },
  {
    title: "Crea anuncios",
    text: "Desde el botón de “Crear” podrás sumar nuevos anuncios a tu tablero.",
    itemBoxProps: { itemId: DASH_BUTTON_ID },
    tooltip: { arrowPosition: "top", position: "button", arrowStyle: { right: "64px" } },
  },
  {
    title: "Busca tus anuncios",
    text: "Una vez creados los anuncios podrás buscarlos por su nombre o por el ID que tengan asignados.",
    itemBoxProps: { itemId: DASH_SEARCHBAR_ID },
    tooltip: { arrowPosition: "top", position: "searchbar", arrowStyle: { left: "64px" } },
  },
  {
    title: "Visualiza el tablero de anuncios ",
    text: "En el tablero podrás ver los datos más relevantes relacionados a los anuncios, así mismo, podrás organizar los contenidos de acuerdo al dato que desees destacar. ",
    itemBoxProps: { itemId: DASH_TABLE_ID },
    tooltip: { arrowPosition: "top", position: "table" },
  }
]