import { useDispatch, useSelector } from "react-redux";
import { fetchSetOnboarding } from "@services";
import { actions as globalActions, globalUserSelector } from "@config/store";
import { COMPLETE_STATUS_ONBOARDING_ID, SKIPPED_STATUS_ONBOARDING_ID } from "@constants";

type StatusType = typeof COMPLETE_STATUS_ONBOARDING_ID | typeof SKIPPED_STATUS_ONBOARDING_ID

const useOnboarding = () => {
  const user = useSelector(globalUserSelector);
  const dispatch = useDispatch();

  const setOnboarding = (id: number, statusId: StatusType) => {
    fetchSetOnboarding({
      userId: user.userId,
      onboardingId: id,
      onboardingStatusId: statusId
    })
      .then(() => dispatch(globalActions.setNewOnboardingIdDone(id)))
  }

  const completeOnboarding = (id: number) => {
    setOnboarding(id, COMPLETE_STATUS_ONBOARDING_ID);
  }

  const skipOnboarding = (id: number) => {
    setOnboarding(id, SKIPPED_STATUS_ONBOARDING_ID);
  }

  return {
    skipOnboarding,
    completeOnboarding
  };
}

export default useOnboarding;
