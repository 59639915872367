import React, { FC, useEffect, ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@chiper-inc/sb-web-chiper';
import { actions, adsCreationCitiesWithPOSSelector, adsCreationCitieselector } from '@modules/ads/store';
import useGetCities from '@modules/ads/hooks/use-get-cities';
import styles from './ads-creation-audience-cities.module.sass';

interface AdsCreationAudienceCitiesProps { }

const AdsCreationAudienceCities: FC<AdsCreationAudienceCitiesProps> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading } = useGetCities();
  const citiesSelected = useSelector(adsCreationCitieselector);
  const citiesWithPOS = useSelector(adsCreationCitiesWithPOSSelector);

  useEffect(() => {
    dispatch(actions.setIsCurrentStepCompleted(!!citiesSelected.length))
  }, [citiesSelected, dispatch]);

  const handleSelectAll = useCallback(() => {
    dispatch(actions.setCreationState({ citiesIds: citiesWithPOS.map(li => li.id) }));
  }, [citiesWithPOS, dispatch]);

  const handleClick = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const { id, checked } = e.target;
    dispatch(actions.setCreationState({
      citiesIds: !!checked
        ? [...citiesSelected, +id]
        : citiesSelected.filter(item => item !== +id)
    }));
  }, [citiesSelected, dispatch]);

  if (isLoading) return <div>...</div>

  return (
    <div className={styles.wrapper}>

      <div className={styles.title}>{t('Selecciona las ciudades correspondientes')}</div>

      <div className={styles.container}>
        <div className={styles.option}>
          <Checkbox
            label={t('Seleccionar todas las ciudades')}
            onChange={handleSelectAll}
            checked={citiesSelected.length === citiesWithPOS.length}
          />
        </div>

        {citiesWithPOS.map((item) => (
          <div className={styles.option} key={`checkbox-city-id-${item.id}`}>
            <Checkbox
              label={t(item.name)}
              onChange={handleClick}
              checked={citiesSelected.includes(item.id)}
              id={item.id.toString()}
              data-testid='city-checkbox'
/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdsCreationAudienceCities;