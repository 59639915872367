import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import owner from '@img/owner.png';
import google from '@img/google-icon.png';
import background from '@img/login-background.png';
import posAdsLogo from '@img/pos-ads-logo.svg';
import useGoogleLogin from '@modules/auth/hooks/use-google-login';
import styles from './auth-login.module.sass';
import UiButton from '@ui/ui-button';

interface AuthLoginProps { }

const AuthLogin: FC<AuthLoginProps> = () => {
  const { t } = useTranslation();
  const { login } = useGoogleLogin();

  return (
    <div className={styles.container}>
      <div className={styles.left} style={{ backgroundImage: `url(${background})` }}>
        <img src={posAdsLogo} alt="" className={styles.logo} />
        <div className={styles.ownerWrapper}>
          <img src={owner} alt="" className={styles.owner} />
        </div>
      </div>

      <div className={styles.right}>

        <div className={styles.texts}>
          <h1 className={styles.title}>
            {t('¡Bienvenido a POS Ads!')}
          </h1>
          <h3 className={styles.subTitle}>
            {t('Inicia sesión con tu cuenta empresarial')}
          </h3>
        </div>

        <UiButton uiType='secondary' onClick={login} height='52px' width='327px'>
          <img src={google} alt="G" className={styles.google} />
          {t('Iniciar sesión con Google')}
        </UiButton>
      </div>
    </div>
  );
}

export default AuthLogin;
