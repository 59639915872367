import { ResponseItem, ResponseItemIdType } from "@entities";
import { ADS_CREATION_Audience_STEP, AUDIENCE_BY_CITIES_TYPE_ID, CREATION_INITIAL_STATE, PROGRAMMING_STANDARD_TYPE_ID } from "../constants";
import { SkuResponseItem } from "../entities/ads.entities";

export interface AdsState {
  ui: AdsUiState;
  data: AdsDataState;
}

export interface AdsUiState {
  currentCreationStep: number;
  isSkusFieldDisabled: boolean;
  isCurrentStepCompleted: boolean;
  audienceCurrentType: number;
  programmingCurrentType: number;
  posSerials: string[];
}

export interface AdsCreationDetailState {
  name: string;
  description: string;
  keywords: string[];
}

export interface AdsStandrardProgrammingState {
  initDate: string;
  endDate: string;
}

export interface AdsEspecificProgrammingStateWeeks {
  value: string;
  text: string;
}

export interface AdsEspecficiProgrammingState {
  initDate: string;
  endDate: string;
  initTime: string;
  endTime: string;
  weekRepeat: string;
  dayRepeat: ResponseItem[];
}

export interface AdsCreationState {
  cpg: ResponseItem | null;
  detail: AdsCreationDetailState;
  owner: string;
  skusByCPG: SkuResponseItem[];
  citiesIds: ResponseItemIdType[];
  citiesWithPOS: ResponseItem[];
  serials: string[];
  identifySerials: string[];
  frequency: ResponseItem[];
  weeks: AdsEspecificProgrammingStateWeeks[];
  standardProgramming: AdsStandrardProgrammingState;
  especificProgramming: AdsEspecficiProgrammingState;
  videoUrl: string;
	advertisingIncome: string;
}

export interface AdsDataState {
  creation: AdsCreationState;
}

export const initialState: AdsState = {
  ui: {
    currentCreationStep: ADS_CREATION_Audience_STEP,
    isSkusFieldDisabled: true,
    isCurrentStepCompleted: false,
    audienceCurrentType: AUDIENCE_BY_CITIES_TYPE_ID,
    programmingCurrentType: PROGRAMMING_STANDARD_TYPE_ID,
    posSerials: [],
  },
  data: {
    creation: CREATION_INITIAL_STATE,
  }
}
