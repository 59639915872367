import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";

import { Countries } from "@entities";
import styles from "./ui-input-field.module.sass";
import { Icon } from "@chiper-inc/sb-web-chiper";

interface UiInputFieldProps {
  label?: string;
  type?: string;
  name?: string;
  value?: string;
  isDisabled?: boolean;
  hasAutoFocus?: boolean;
  hasError?: boolean;
  error?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  helperText?: string;
  placeholder?: string;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  maxCharacters?: number;
  isReadOnly?: boolean;
  isPhone?: boolean;
  countries?: Countries[];
  onCountrySelect?: (areaCode: number) => void;
  countrySelected?: number;
}

const UiInputField: FC<UiInputFieldProps> = ({
  hasError = false,
  label = "",
  name,
  value,
  isDisabled,
  hasAutoFocus,
  type = "text",
  onChange = () => true,
  onBlur,
  helperText = "",
  placeholder = "",
  onFocus,
  error = "",
  maxCharacters,
  isReadOnly,
  isPhone,
  countries,
  onCountrySelect,
  countrySelected = 57,
}: UiInputFieldProps): JSX.Element => {
  const [countryCode, setCountryCode] = useState<number>(countrySelected);
  const [showCountries, setShowCountries] = useState<boolean>(false);
  const countriesContainerRef = useRef<HTMLDivElement>(null);
  const countriesWrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!maxCharacters || e.target.value.length <= maxCharacters) onChange(e);
  };

  const handleCountrySelect = (areaCode: number) => {
    setCountryCode(areaCode);
    setShowCountries(false);
    if (onCountrySelect) {
      onCountrySelect(areaCode);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        countriesContainerRef.current &&
        !countriesContainerRef.current.contains(event.target as Node) &&
        countriesWrapperRef.current &&
        !countriesWrapperRef.current.contains(event.target as Node)
      ) {
        setShowCountries(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const country = () => {
    return countries!.find((i) => i.code === countryCode);
  };

  return (
    <div className={styles.container}>
      <div
        className={`
        ${styles.wrapper} 
        ${hasError && styles.error} 
        ${isDisabled && styles.disabled}
      `}
      >
        {isPhone && (
          <div
            className={styles.countryCode}
            onClick={() => setShowCountries(!showCountries)}
            ref={countriesWrapperRef}
            data-testid="area-code-options"
          >
            <div className={styles.country} data-testid="phone-indicator">
              <img src={country()!.img} alt="Flag" className={styles.img} />
              <span className={styles.text}>+{country()!.code}</span>
              <div className={`${styles.arrow} ${showCountries && styles.up}`}>
                <Icon name="CaretDown" size="small" />
              </div>
            </div>
          </div>
        )}
        <span className={`${styles.label} ${!!value && styles.visible}`}>
          {label}
        </span>
        <input
          data-testid="ui-input-field"
          type={type}
          name={name}
          value={value}
          className={`${styles.input} ${isPhone && styles.isPhone}`}
          onChange={handleInputChange}
          onBlur={onBlur}
          autoFocus={hasAutoFocus}
          disabled={isDisabled}
          placeholder={placeholder}
          autoComplete="off"
          onFocus={onFocus}
          readOnly={isReadOnly}
        />
      </div>

      {showCountries && (
        <div className={styles.items} ref={countriesContainerRef}>
          {countries!.map((e, index) => (
            <div
              className={styles.item}
              key={`country-code-${index}`}
              onClick={() => handleCountrySelect(e.code)}
            >
              <img src={e.img} alt="Flag" className={styles.flag} />
              <div className={styles.name}>+{e.code}</div>
            </div>
          ))}
        </div>
      )}

      {hasError && !!error ? (
        <p className={styles.errorMessage}>{error}</p>
      ) : (
        !!helperText && <p className={styles.helperText}>{helperText}</p>
      )}

      {!!maxCharacters && (
        <span className={styles.count}>
          {value?.length || "0"}/{maxCharacters}
        </span>
      )}
    </div>
  );
};

export default memo(UiInputField);
