import React, { FC, ReactNode, memo, useCallback, useEffect, useRef } from 'react';
import styles from './ui-modal.module.sass';
import { Icon } from '@chiper-inc/sb-web-chiper';
import useOutsideClickDetector from '@hooks/use-outside-click-detector';

interface UiModalProps {
  children: ReactNode;
  closeFunction: () => void;
  className?: string;
  blockDetectors?: boolean;
}

const UiModal: FC<UiModalProps> = ({
  children,
  closeFunction,
  className,
  blockDetectors
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    if (!blockDetectors) closeFunction();
  }, [blockDetectors, closeFunction]);

  useOutsideClickDetector(modalRef, handleClose);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") handleClose();
    }
    document.addEventListener("keydown", handleKeyPress);
    return () => { document.removeEventListener("keydown", handleKeyPress); };
  }, [handleClose]);

  return (
    <div className={styles.container}>
      <div ref={modalRef} className={`${styles.modal} ${className}`}>

        <div className={styles.close} onClick={closeFunction}>
          <Icon name='X' size={24} />
        </div>

        {children}
      </div>
    </div>
  );
}

export default memo(UiModal);
