import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomeDashboard from '../pages/home-dashboard';

interface HomeRouterProps { }

const HomeRouter: FC<HomeRouterProps> = () => {

  return (
    <Routes>
      <Route index element={<HomeDashboard />} />
    </Routes>
  );
}

export default HomeRouter;
