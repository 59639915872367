import React, { FC, memo, useState } from 'react';
import { addMonths, endOfMonth, format, getDaysInMonth, isAfter, isBefore, isSameDay, isToday, setDate, startOfMonth, subMonths } from 'date-fns';
import { es } from 'date-fns/locale';
import styles from './ui-date-picker.module.sass';
import { Icon } from '@chiper-inc/sb-web-chiper';

const WEEK_DAYS: string[] = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];

interface UiDatePickerProps {
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onClick: (date: Date) => void;
}

const UiDatePicker: FC<UiDatePickerProps> = ({ value, minDate, maxDate, onClick }) => {
  const [pickerDate, setPickerDate] = useState<Date>(value || new Date());

  const isSelectedDate = (day: number) => !!value && isSameDay(value, setDate(pickerDate, day));

  const isDisabledDay = (day: number) => {
    const currentDate = setDate(pickerDate, day);
    return (!!maxDate && isAfter(currentDate, maxDate)) || (!!minDate && (isBefore(currentDate, minDate)));
  }

  const getDaysBeforeCurrentMonth = () => {
    let firstDayIndex = +format(startOfMonth(pickerDate), 'c', { locale: es }) - 1;
    const daysOfPrevMonth = getDaysInMonth(subMonths(new Date(pickerDate), 1));
    return new Array(firstDayIndex).fill('').map((_, i) => (
      <div key={`day-number-before-${i}`} className={styles.empty}>
        {daysOfPrevMonth - --firstDayIndex}
      </div>
    ))
  }

  const getDaysAfterCurrentMonth = () => {
    let lastDayIndex = +format(endOfMonth(pickerDate), 'c', { locale: es });
    return new Array(7 - lastDayIndex).fill('').map((_, i) => (
      <div key={`day-number-after-${i}`} className={styles.empty}>
        {i + 1}
      </div>
    ))
  }

  const handleDateClick = (day: number) => {
    if (!isDisabledDay(day)) onClick(setDate(pickerDate, day));
  }

  const getDaysOfCurrentMonth = () => {
    const daysNumber = getDaysInMonth(pickerDate);
    return new Array(daysNumber).fill(daysNumber).map((_, i) => (
      <div key={`day-number-current-${i}`} className={styles.cell}>
        <div
          className={`${styles.day} 
          ${isToday(setDate(pickerDate, i + 1)) && styles.today}
          ${isSelectedDate(i + 1) && styles.selected}
          ${isDisabledDay(i + 1) && styles.disabled}
        `}
          onClick={() => handleDateClick(i + 1)}
        >
          {i + 1}
        </div>
      </div>
    ));
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.icon} onClick={() => setPickerDate(subMonths(pickerDate, 1))}>
          <Icon name='CaretLeft' size={16} />
        </div>
        {`${format(pickerDate, 'LLLL', { locale: es })} ${format(pickerDate, 'yyyy', { locale: es })}`}
        <div className={styles.icon} onClick={() => setPickerDate(addMonths(pickerDate, 1))}>
          <Icon name='CaretRight' size={16} />
        </div>
      </div>

      <div className={styles.week}>
        {WEEK_DAYS.map((d,i) => (
          <div key={`cell-day-${d}-${i}`} className={styles.dayName}>
            {d}
          </div>
        ))}
      </div>

      <div className={styles.calendar}>
        {getDaysBeforeCurrentMonth()}
        {getDaysOfCurrentMonth()}
        {getDaysAfterCurrentMonth()}
      </div>
    </div>
  );
}

export default memo(UiDatePicker);
