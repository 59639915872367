import React, { FC } from 'react';
import styles from './admin-home-container.module.sass';
import { NavLink, Outlet } from 'react-router-dom';
import {  TabList } from '@chiper-inc/sb-web-chiper';
import { useTranslation } from 'react-i18next';
import { ADMIN_HOME_TABS } from '@modules/admin/constants';

const TAB_ITEMS = [
  { title: 'Usuarios', routeTo: ADMIN_HOME_TABS.users },
  { title: 'Roles', routeTo: ADMIN_HOME_TABS.roles },
  { title: 'Agencias', routeTo: ADMIN_HOME_TABS.agencies },
]

interface AdminHomeContainerProps { }

const AdminHomeContainer: FC<AdminHomeContainerProps> = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <TabList itemWidth={140}>
          {TAB_ITEMS.map((item) => (
            <NavLink
              className={({ isActive }) => `${styles.tab} ${isActive ? styles.active : ''}`}
              key={`menu-item-${item.title}`}
              to={item.routeTo}
            >
              {t(item.title)}
            </NavLink>
          ))}
        </TabList>
      </div>

      <Outlet />
    </div>
  );
}

export default AdminHomeContainer;
