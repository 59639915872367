import React, { FC, memo } from 'react';
import { ResponseItem } from '@entities';
import styles from './home-dashboard-table-detail-list.module.sass';

interface HomeDashboardTableDetailListProps {
  items: Partial<ResponseItem>[];
}

const HomeDashboardTableDetailList: FC<HomeDashboardTableDetailListProps> = ({ items }) => {

  return (
    <div className={styles.wrapper}>
      {items.map((e, i) => (
        <div className={styles.item} key={`table-detail-item-${e.name}-${i}`}>
          {e.imageUrl && (
            <img src={e.imageUrl} alt="phot" className={styles.img} />
          )}
          <span className={styles.text}>{e.name}</span>
        </div>
      ))}
    </div>
  );
}

export default memo(HomeDashboardTableDetailList);
