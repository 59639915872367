export interface AdminState {
  ui: AdminUiState
  data: AdminDataState
}

export interface AdminUiState { }

export interface AdminDataState { 
  roles: AdminRoles[];
  users: AdminUsers[];
  agencies: AdminAgencies[];
  searchValue: string;
}

export interface AdminRoles { 
  id: number;
  roleName: string;
  usersCount: number;
  stateId: number;
}

export interface AdminUsers {
  id: number;
  name: string;
  email: string;
  phone: string;
  agencyName: string;
  adsCount: number;
  stateId: number;
}

export interface AdminAgencies {
  id: number;
  agencyName: string;
  adminEmail: string;
  usersCount: number;
  stateId: number;
}

export const initialState: AdminState = {
  ui: [],
  data: {
    roles: [],
    users: [],
    agencies: [],
    searchValue: ''
  }
}
