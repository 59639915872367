import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Theme } from '@chiper-inc/sb-web-chiper';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { actions, adsCreationStepSelector } from '@modules/ads/store';
import { ADS_CREATION_PROGRESS_STEPS, CREATION_RETURN_BUTTON_ROUTES } from '@modules/ads/constants';
import AdsCreationButtons from '../ads-creation-buttons';
import UiProgressHeader from '@ui/ui-progress-header';
import styles from './ads-creation-container.module.sass';

interface AdsCreationContainerProps { }

const AdsCreationContainer: FC<AdsCreationContainerProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentStep = useSelector(adsCreationStepSelector);

  useEffect(() => {
    return () => { dispatch(actions.restartCreationState()); }
  }, [dispatch]);

  const onBackClick = () => {
    navigate(CREATION_RETURN_BUTTON_ROUTES.get(currentStep) || '')
  }

  return (
    <div className={styles.container}>
      <UiProgressHeader
        steps={ADS_CREATION_PROGRESS_STEPS}
        currentStep={currentStep}
      />

      <div className={styles.title}>
        <div className={styles.icon} onClick={onBackClick}>
          <Icon
            color={Theme.colors.primary.darkBlueGray}
            name='CaretLeft'
            size='24px'
            weight='light'
          />
        </div>

        {t(ADS_CREATION_PROGRESS_STEPS.find(e => e.step === currentStep)?.key || '')}
      </div>

      <Outlet />

      <AdsCreationButtons />
    </div>
  );
}

export default AdsCreationContainer;
