import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  adsCreationAudiencePOSSerialsSelector,
  adsCreationSerialsSelector,
} from "../store";

const useHandleSerials = () => {
  const dispatch = useDispatch();
  const uploadedSerials = useSelector(adsCreationSerialsSelector);
  const POSSerials = useSelector(adsCreationAudiencePOSSerialsSelector);

  const identifiedSerials = useMemo(() => {
    let serials = [];
    for (const e of uploadedSerials) {
      if (POSSerials.includes(e)) serials.push(e);
    }
    dispatch(actions.setCreationState({ identifySerials: serials }))
    return serials;
  }, [POSSerials, dispatch, uploadedSerials]);

  return identifiedSerials.length
}

export default useHandleSerials;
