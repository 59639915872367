import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as globalActions } from '@config/store';
import { fetchCreateAgency } from "../services";
import { useTranslation } from "react-i18next";
import { showNotification } from "@utils";
import { AgencyForm } from "../entities";

const useAgencyCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createNewAgency = useCallback((agency: AgencyForm): void => { 
    dispatch(globalActions.setIsLoading(true));
    const body: AgencyForm = {
      name: agency.name,
      adminName: agency.adminName,
      taxNumber: agency.taxNumber,
      adminEmail: agency.adminEmail
    }

    fetchCreateAgency(body)
      .then(() => {
        showNotification(t('Agencia creada exitosamente.'), 'success');
        navigate(-1)
      })
      .catch(() => {
        showNotification(t('Lo sentimos, la agencia no se ha creado'), 'error');
      })
      .finally(() => {
        dispatch(globalActions.setIsLoading(false));
      })
  }, [dispatch, navigate, t]);

  return {
    createNewAgency
  }
}

export default useAgencyCreation;
