import { useSelector } from "react-redux";
import Clevertap from "@config/clevertap";
import { adsCreationAudienceTypeSelector, adsCreationProgrammingTypeSelector, adsCreationStepSelector } from "../store";

const useClevertapEvent = () => {
  const step = useSelector(adsCreationStepSelector);
  const audience = useSelector(adsCreationAudienceTypeSelector);
  const programming = useSelector(adsCreationProgrammingTypeSelector);

  const event: { [key: number]: () => void } = {
    1: () => Clevertap.pushEvent("CompleteDefinitionPAds"),
    2: () => Clevertap.pushEvent("CompleteAudiencePAds", { id: audience }),
    3: () => Clevertap.pushEvent("CompleteProgramationPAds", { id: programming }),
    4: () => Clevertap.pushEvent("CompleteFormatPAds"),
  };

  const setEvent = () => {
    event[step]();
  }

  return {
    setEvent
  }
}

export default useClevertapEvent;
