import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, adsCreationProgrammingTypeSelector } from '@modules/ads/store';
import { ADS_CREATION_PROGRAMMING_STEP, PROGRAMMING_STANDARD_TYPE_ID } from '@modules/ads/constants';
import AdsCreationProgrammingStandard from '@modules/ads/components/ads-creation-programming-standard';
import AdsCreationProgrammingEspecific from '@modules/ads/components/ads-creation-programming-especific/ads-creation-programming-especific';

import AdsCreationProgrammingOptions from '@modules/ads/components/ads-creation-programming-options';
import AdsCreationProgrammingManager from '@modules/ads/components/ads-creation-programming-manager';
import styles from './ads-creation-programming.module.sass';

interface AdsCreationProgrammingProps { }

const AdsCreationProgramming: FC<AdsCreationProgrammingProps> = () => {
  const dispatch = useDispatch();
  const currentProgrammingType = useSelector(adsCreationProgrammingTypeSelector);

  useEffect(() => {
    dispatch(actions.setCurrentCreationStep(ADS_CREATION_PROGRAMMING_STEP))
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <AdsCreationProgrammingOptions />

      {currentProgrammingType === PROGRAMMING_STANDARD_TYPE_ID
        ? <AdsCreationProgrammingStandard />
        : <AdsCreationProgrammingEspecific />
      }

      <AdsCreationProgrammingManager />
    </div>
  );
}

export default AdsCreationProgramming;
